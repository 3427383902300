import React from "react";
import {
  IconSVG,
  mapping,
  FormatDate,
  riskEditStyle,
} from "@aim-mf/styleguide";

class RiskVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskDetail,
      createNewRisk: false,
    };
  }

  render() {
    return (
      <div style={riskEditStyle.riskVersionWrapperStyle}>
        <div style={riskEditStyle.sectionTitleStyle}>VERSION</div>
        <div
          className={"scrollbar"}
          style={riskEditStyle.riskVersionContentWrapperStyle}
        >
          {this.props.riskDetail.versions.length !== 0 &&
            this.props.riskDetail.versions
              .sort((a, b) => {
                return new Date(a.createdDatetime) < new Date(b.createdDatetime)
                  ? 1
                  : -1;
              })
              .map((version) => {
                return (
                  <button
                    onClick={() => {
                      this.props.handleUpdateWithNewRiskDetail(version.id);
                    }}
                    style={
                      this.props.riskDetail.id === version.id ||
                      this.state.versionButtonHoverID === version.id
                        ? riskEditStyle.versionButtonHover
                        : riskEditStyle.versionButton
                    }
                    onMouseEnter={() => {
                      this.setState({ versionButtonHoverID: version.id });
                      // eslint-disable-next-line no-console
                      console.log(version.id);
                    }}
                    onMouseLeave={() => {
                      this.setState({ versionButtonHoverID: -1 });
                    }}
                  >
                    <div style={riskEditStyle.versionButtonContentStyle}>
                      <div
                        style={{
                          ...riskEditStyle.versionButtonLeftIconStyle,
                          backgroundColor:
                            this.props.riskDetail.id === version.id
                              ? mapping["Color_Basic_Primary"]
                              : mapping["Color_Basic_Secondary"],
                        }}
                      >
                        {version.status === "active" ? (
                          version.is_delegate || version.is_owner ? (
                            <IconSVG
                              name={"branchActive"}
                              color={"white"}
                              size={1}
                              center={"left_top"}
                            />
                          ) : (
                            <IconSVG
                              name={"commitActive"}
                              color={"white"}
                              size={1}
                              center={"left_top"}
                            />
                          )
                        ) : version.is_delegate || version.is_owner ? (
                          <IconSVG
                            name={"branchInactive"}
                            color={"white"}
                            size={1}
                            center={"left_top"}
                          />
                        ) : (
                          <IconSVG
                            name={"commitInactive"}
                            color={"white"}
                            size={1}
                            center={"left_top"}
                          />
                        )}
                      </div>
                      <div style={riskEditStyle.versionContentWrapper}>
                        <div style={riskEditStyle.versionButtonNameStyle}>
                          {version.createdUser}
                        </div>
                        <div
                          style={riskEditStyle.versionCreatedDateContentWrapper}
                        >
                          <div style={riskEditStyle.versionButtonDateStyle}>
                            {FormatDate(version.createdDatetime, "DD/MM/YYYY")}
                          </div>
                          <div style={riskEditStyle.versionButtonStatusStyle}>
                            {version.status}
                          </div>
                        </div>
                      </div>
                      <div style={riskEditStyle.moreIconWrapper}>
                        <IconSVG name={"moreHori"} color={"white"} />
                      </div>
                    </div>
                  </button>
                );
              })}
        </div>
        <div style={riskEditStyle.versionDotLineStyle} />
        <div style={riskEditStyle.versionButtonViewMoreWrapperStyle}>
          <div style={riskEditStyle.versionDotCircleStyle} />
        </div>
      </div>
    );
  }
}

export { RiskVersion };
