import React from "react";
import {
  CustomizedAutoComplete,
  AutoSizeTextArea,
  riskEditStyle,
} from "@aim-mf/styleguide";

class RiskMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskDetail,
      createNewRisk: false,
    };
  }

  render() {
    let autoCompleteParentEntityRiskData = this.props.parentEntityRisk.map(
      ({ id, name }) => {
        return { id, name };
      }
    );

    let mappingRiskName = this.props.parentEntityRisk.filter((risk) => {
      return risk.id === this.props.mapping_risk_id;
    })[0];
    if (mappingRiskName) {
      mappingRiskName = mappingRiskName.name;
    }

    return (
      <div style={riskEditStyle.riskMapStyle}>
        <div style={riskEditStyle.riskMapTitleStyle}> Risk Map</div>
        <div style={riskEditStyle.riskMapContentStyle}>
          <div style={riskEditStyle.riskFromStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>
              RISK name from entity
            </div>

            <TextInput
              customizedStyles={riskEditStyle.mapSourceTextStyle}
              width={"100%"}
              value={this.props.name}
              disabled={true}
            />
          </div>
          <div style={riskEditStyle.riskFromStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>Global risk name</div>
            <CustomizedAutoComplete
              // data ternary function is to only enable dropdown for current autocomplete
              inputFieldStyle={riskEditStyle.mapInputFieldStyle}
              data={autoCompleteParentEntityRiskData}
              defaultValue={mappingRiskName}
              filterKey={"name"}
              displayID={false}
              onCustomChange={(event) => {
                let mappingID = this.getRiskIDFromName(event.value);
                this.props.handleRiskMappingChange(mappingID);
              }}
              //handleSearchGroupRisk={handleControlSearch}
              //handleriskDetailRequest={props.handleRiskDetailRequest}
              //searching={props.searching}
            />
          </div>
        </div>
      </div>
    );
  }
  getRiskIDFromName = (name) => {
    let risk = this.props.parentEntityRisk.filter((risk) => {
      return risk.name === name;
    })[0];
    if (risk) {
      return risk.id;
    } else {
      return this.props.id;
    }
  };
}

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{
        ...riskEditStyle.mapInputFieldStyle,
        width: props.width,
        ...props.customizedStyles,
      }}
      value={props.value}
      CustomizedOnChange={onChange}
      disabled={props.disabled}
    />
  );
};

export { RiskMap };
