import React from "react";
import { RiskVersion } from "./riskRegisterComponents/riskVersion";
import { RiskMap } from "./riskRegisterComponents/riskMap";
import {
  IconSVG,
  mapping,
  Pill,
  ButtonSolid,
  Risk_Matrix_Component_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  AutoSizeTextArea,
  CustomizedCheckBox,
  RichTextEditor,
  riskEditStyle,
} from "@aim-mf/styleguide";

class RiskBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      versionButtonHoverID: -1,
    };
  }

  render() {
    const RiskStatus = "Working Draft";
    const RiskStatusBGColor = "#FDD60066";

    //console.log(this.props.riskDetail)
    //console.log(this.props.parentRiskDropDownItem)

    return (
      <div style={riskEditStyle.RiskBasicInfoStyle}>
        <div style={{ display: "inline-flex" }}>
          <IconSVG
            name={"home"}
            size={0.8}
            color={mapping["Color_Basic_Primary"]}
          />
          <div
            style={{ ...riskEditStyle.parentRiskStyle, alignSelf: "center" }}
          >
            {this.props.riskDetail.isParentRisk ? "Parent Risk" : "Sub Risk"}
          </div>
        </div>
        <div style={riskEditStyle.riskStatusStyle}>
          <Pill color={RiskStatusBGColor} content={RiskStatus} />
        </div>
        <div style={{ display: "inline-flex", marginBottom: "0.625rem" }}>
          <TextInput
            customizedStyles={riskEditStyle.TextInputStyle}
            width={riskEditStyle.riskNumberInputWidth}
            value={this.props.riskDetail.riskNumber}
            onCustomChange={this.handleRiskNumberChange}
            disabled={
              !this.props.rights.edit ||
              !this.props.riskDetail.access_right.edit
            }
          />
          <TextInput
            customizedStyles={riskEditStyle.TextInputStyle}
            width={riskEditStyle.riskNameInputWidth}
            value={this.props.riskDetail.name}
            onCustomChange={this.handleRiskNameChange}
            disabled={
              !this.props.rights.edit ||
              !this.props.riskDetail.access_right.edit
            }
          />
        </div>
        <div style={riskEditStyle.riskInfoSectionWrapperStyle}>
          <div style={riskEditStyle.descriptionWrapperStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>description</div>
            <RichTextEditor
              style={riskEditStyle.riskDescriptionWidth}
              value={this.props.riskDetail.description}
              onChange={this.handleRiskDescriptionChange}
              disabled={
                !this.props.rights.edit ||
                !this.props.riskDetail.access_right.edit
              }
            />
          </div>

          {/*risk owner section*/}
          <div style={riskEditStyle.riskOwnerWrapperStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>RISK OWNER</div>
            {this.props.riskDetail.owner.map((owner) => {
              return (
                <div style={riskEditStyle.riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        if (a[0]) {
                          return a[0].toUpperCase();
                        }
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {this.props.rights.create && this.props.rights.edit && (
              <div style={{ paddingTop: "1.25rem" }}>
                <ButtonSolid
                  name={"Add\u00a0\u00a0+"}
                  fontStyle={mapping["Font_Button_Badge_White"]}
                  height={riskEditStyle.buttonHeight}
                  width={riskEditStyle.buttonWidth}
                  size={"Small"}
                  clickEvent={this.handleOwnerChange}
                />
              </div>
            )}
          </div>

          {/*risk delegate section*/}
          <div style={riskEditStyle.riskDelegateWrapperStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>RISK DELEGATES</div>
            {this.props.riskDetail.delegate.map((owner) => {
              return (
                <div style={riskEditStyle.riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {this.props.rights.delegate === 1 && this.props.rights.edit === 1 && (
              <div style={{ paddingTop: "1.25rem" }}>
                <ButtonSolid
                  name={"Add\u00a0\u00a0+"}
                  fontStyle={mapping["Font_Button_Badge_White"]}
                  height={riskEditStyle.buttonHeight}
                  width={riskEditStyle.buttonWidth}
                  size={"Small"}
                  clickEvent={this.handleDelegateChange}
                />
              </div>
            )}
          </div>

          {/*risk rating section*/}
          <div style={riskEditStyle.riskRatingWrapperStyle}>
            <div style={riskEditStyle.sectionTitleStyle}>RISK RATING</div>
            <div style={riskEditStyle.riskRatingContentStyle}>
              <Risk_Matrix_Component_Block
                MatrixSetting={this.state.matrixSetting}
                singleRisk={true}
                riskRating={[
                  {
                    Gross: this.props.riskDetail.gross,
                    Target: this.props.riskDetail.target,
                    Residual: this.props.riskDetail.residual,
                  },
                ]}
                categoryList={["Gross", "Residual", "Target"]}
              />
            </div>
          </div>

          {/*risk version section*/}
          <RiskVersion
            riskDetail={this.props.riskDetail}
            handleUpdateWithNewRiskDetail={
              this.props.handleUpdateWithNewRiskDetail
            }
          />
        </div>
        <div>
          <div
            style={{
              display: "inline-flex",
              color: "white",
              marginTop: "1rem",
            }}
          >
            {/*risk tier section*/}
            <div style={riskEditStyle.RiskTierWrapperStyle}>
              <div style={riskEditStyle.sectionTitleStyle}>Risk Tier</div>
              <div style={riskEditStyle.riskTierDropDownWrapperStyle}>
                <DropDownListButton
                  value={"Tier " + this.props.riskDetail.tier}
                  data={
                    this.props.riskDetail.isParentRisk
                      ? ["Tier 1", "Tier 2", "Tier 3"]
                      : ["Tier 1", "Tier 2", "Tier 3"].slice(
                          this.props.riskDetail.tier
                        )
                  }
                  CustomOnChange={this.props.handleRiskTierChange}
                  DropDownButtonStyle={
                    riskEditStyle.riskTierDropDownButtonStyle
                  }
                  disabled={!this.props.rights.edit}
                />
              </div>
            </div>
            {/*risk category section*/}
            <div style={riskEditStyle.RiskCategoryWrapperStyle}>
              <div style={riskEditStyle.sectionTitleStyle}>Category</div>
              <div style={riskEditStyle.riskCategoryDropDownWrapperStyle}>
                <DropDownListButton
                  value={this.props.riskDetail.category}
                  data={this.props.CategoryList}
                  CustomOnChange={this.props.handleCategoryChange}
                  DropDownButtonStyle={
                    riskEditStyle.riskTierDropDownButtonStyle
                  }
                  disabled={
                    !this.props.rights.edit ||
                    !this.props.riskDetail.isParentRisk
                  }
                />
              </div>
            </div>
            {!this.props.riskDetail.isParentRisk && (
              <div style={riskEditStyle.RiskParentWrapperStyle}>
                <div style={riskEditStyle.sectionTitleStyle}>Parent Risk</div>
                <div style={riskEditStyle.riskParentDropDownWrapperStyle}>
                  <DropDownListButton
                    value={{ name: this.props.riskDetail.parent_risk_name }}
                    data={this.props.parentRiskDropDownItem}
                    dataDisplayKey={"name"}
                    CustomOnChange={this.props.handleParentRiskChange}
                    DropDownButtonStyle={
                      riskEditStyle.riskTierDropDownButtonStyle
                    }
                    disabled={
                      !this.props.rights.edit ||
                      !this.props.riskDetail.access_right.edit ||
                      this.props.riskDetail.isParentRisk
                    }
                  />
                </div>
              </div>
            )}
            <div style={riskEditStyle.RiskTypeWrapperStyle}>
              <div style={riskEditStyle.sectionTitleStyle}>Risk Type</div>
              <div
                style={{ display: "flex", flexWrap: "wrap", color: "white" }}
              >
                {this.props.riskTypeList.map((type) => {
                  return (
                    <div style={{ paddingRight: "1.3rem", flex: "0 0 33.33%" }}>
                      <CustomizedCheckBox
                        value={this.props.riskDetail[type.name]}
                        label={type.label}
                        onCheckChange={() => {
                          this.props.handleRiskTypeChange(type.name);
                        }}
                        checkBoxWrapperStyle={{ marginLeft: 0 }}
                        disabled={
                          !this.props.rights.edit ||
                          !this.props.riskDetail.access_right.edit
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {this.props.isSubEntity && (
          <div>
            <RiskMap
              id={this.props.riskDetail.id}
              name={this.props.riskDetail.name}
              parentEntityRisk={this.props.parentEntityRisks}
              mapping_risk_id={this.props.riskDetail.mapping_risk_id}
              handleRiskMappingChange={this.handleRiskMappingChange}
            />
          </div>
        )}
        {this.props.export !== true && (
          <div style={{ display: "inline-flex" }}>
            <Tab_BookMark
              OnTabChange={this.props.handleTabChange}
              tabWidth={"9rem"}
              height={"3.5rem"}
              labels={["Risk Summary", "Risk Management"]}
              activeBackgroundColor={mapping["Color_BG_05_solid"]}
            />
          </div>
        )}
        <div style={riskEditStyle.riskEditSubmitButtonWrapperStyle}>
          {this.props.rights.delegate === 1 && false && (
            <ButtonSolid
              name={"Send to Delegates"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Delegate Risk",
                  "You are about to delegate this risk. A new draft version will be created.",
                  this.props.handleSendToDelegate
                );
              }}
              size={"Small"}
              height={riskEditStyle.riskEditPopupButtonHeight}
              width={riskEditStyle.riskEditPopupButtonWidth}
              disabled={this.props.disableSendDelegate}
            />
          )}
          <div style={riskEditStyle.riskEditButtonSeparatorWidth} />
          {this.props.rights.create === 1 && false && (
            <ButtonSolid
              name={"Send to Owner"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Change Risk Owner",
                  "You are about to change the owner of this risk. A new draft version will be created.",
                  this.props.handleSendToOwner
                );
              }}
              size={"Small"}
              height={riskEditStyle.riskEditPopupButtonHeight}
              width={riskEditStyle.riskEditPopupButtonWidth}
              disabled={this.props.disableSendOwner}
            />
          )}
          <div style={riskEditStyle.riskEditButtonSeparatorWidth} />
          {this.props.rights.edit === 1 && (
            <ButtonSolid
              name={"Save Changes"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Save Changes",
                  "You are about to save changes made to this risk. A new draft version will be created.",
                  this.props.handleSaveAsDraft
                );
              }}
              size={"Small"}
              height={riskEditStyle.riskEditPopupButtonHeight}
              width={riskEditStyle.riskEditPopupButtonWidth}
              disabled={
                this.props.disableSaveChange || this.props.rights.edit === 0
              }
            />
          )}
          <div style={riskEditStyle.riskEditButtonSeparatorWidth} />
          {this.props.rights.approve === 1 && (
            <ButtonSolid
              name={"Approve"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Approve Risk",
                  "You are about to approve this risk. the status of this risk will be active.",
                  this.props.handleApprove
                );
              }}
              size={"Small"}
              height={riskEditStyle.riskEditPopupButtonHeight}
              width={riskEditStyle.riskEditPopupButtonWidth}
              disabled={this.props.riskDetail.status === "active"}
            />
          )}
          {this.props.rights.approve === 0 && this.props.rights.edit === 1 && (
            <ButtonSolid
              name={"Submit Changes"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Risk submission",
                  "You are about to submit this risk to the risk owner or risk manager. A new version will be created, and you will not be able to make any changes to this risk.",
                  this.props.handleSubmitChange
                );
              }}
              size={"Small"}
              height={riskEditStyle.riskEditPopupButtonHeight}
              width={riskEditStyle.riskEditPopupButtonWidth}
              disabled={
                this.props.disableSaveChange || this.props.rights.edit === 0
              }
            />
          )}
        </div>
      </div>
    );
  }
  handleOwnerChange = () => {
    this.props.handleAddUser(
      "risk",
      -1,
      this.props.riskDetail.owner,
      "Assign Risk Owner"
    );
  };
  handleDelegateChange = () => {
    this.props.handleAddUser(
      "delegate",
      -1,
      this.props.riskDetail.delegate,
      "Assign Risk Delegate"
    );
  };
  handleRiskDescriptionChange = (value) => {
    if (this.props.handleRiskDescriptionChange) {
      this.props.handleRiskDescriptionChange(value);
    }
  };
  handleRiskNameChange = (value) => {
    if (this.props.handleRiskNameChange) {
      this.props.handleRiskNameChange(value);
    }
  };
  handleRiskNumberChange = (value) => {
    if (this.props.handleRiskNumberChange) {
      this.props.handleRiskNumberChange(value);
    }
  };
  handleRiskMappingChange = (value) => {
    if (this.props.handleRiskMappingChange) {
      this.props.handleRiskMappingChange(value);
    }
  };
}

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{
        ...riskEditStyle.InputFieldStyle,
        width: props.width,
        ...props.customizedStyles,
      }}
      value={props.value}
      CustomizedOnChange={onChange}
      disabled={props.disabled}
    />
  );
};

export { RiskBasicInfo };
