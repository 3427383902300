import React from "react";
import {ABCDemoRiskTower, dashboardStyle, DropDownListButton, Loading, mapping, SearchBar,} from "@aim-mf/styleguide";
import {navigateToUrl} from "single-spa";

const parentRiskCountBackgroundColor =
  mapping["Color_Parent_Risk_Count_Background"];
const subRiskCountBackgroundColor = mapping["Color_Basic_Primary"] + "33";
const subRiskCountBackgroundColorSelected =
  mapping["Color_Basic_Primary"] + "FF";

class ABCDemoRiskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList: [
        { name: "sustainability", label: "Sustainability" },
        { name: "ABC", label: "ABC" },
        { name: "fraud", label: "Fraud" },
        { name: "dataPrivacy", label: "Data Privacy" },
        { name: "cyberSecurity", label: "Cyber Security" },
        { name: "criticalInfra", label: "Critical Infra" },
        { name: "others", label: "Others" },
      ],
      typeFilterList: [
        "East Asia",
        "South Asia",
        "South East Asia",
        "Central Asia",
        "West Asia",
      ],
      riskList: this.props.riskList,
      riskMatrixSetting: this.props.riskMatrixSetting,
      categoryList: this.props.categoryList,
      riskNumberPerRow: this.props.riskNumberPerRow,
      riskRating: this.props.riskRating,
      //filter list [tier filter, rating filter, and search filter, and risk type]
      filterList: [0, "", "", ""],
      tierFilterList: ["Global", "Region", "Local"],
      currentTier: 0,
      ratingFilterList: [
        "Western Europe",
        "Central and Eastern Europe",
        "Asia",
        "Mediterranean & Middle East",
        "Americas",
        "Africa",
      ],
      currentRating: 0,
      currentType: 0,
      MatrixExplanation: false,
      MatrixLikelihoodExplanation: false,
      MatrixImpactExplanation: false,
      levelFilter: "ParentRisk",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(nextProps.riskList) !== JSON.stringify(this.props.riskList)
    ) {
      this.setState({ riskList: nextProps.riskList });
    }
    if (
      JSON.stringify(nextProps.riskRating) !==
      JSON.stringify(this.props.riskRating)
    ) {
      this.setState({ riskRating: nextProps.riskRating });
    }
  }

  HandleRiskTileClick = (ID) => {
    // navigateToUrl("/risk-edit/" + ID + "/");
      console.log("tile "+ID+" clicked")
  };


  render() {
    // console.log(this.props.loading)
    const riskTileHoverCallback = (risk) => {
      this.setState({ riskRating: risk });
    };

    const riskTileUnhoverCallback = () => {
      this.setState({ riskRating: this.props.riskRating });
    };

    let displayData = this.filterData(this.props.riskList);
    return (
      <div style={{ width: "100%" }}>
        <div
          style={
            this.state.MatrixExplanation ||
            this.state.MatrixImpactExplanation ||
            this.state.MatrixLikelihoodExplanation ||
            this.props.loading
              ? BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={{ margin: "2rem 0 2rem 10rem" }}>
            <div
              style={dashboardStyle.RiskCountAlignStyle}
              data-test={"dashboard_filter_tier"}
            >
              <DropDownListButton
                data={this.state.tierFilterList}
                value={this.state.tierFilterList[0]}
                onChange={this.onTierChange}
              />
            </div>
            {this.state.filterList[0] !== 0 && (
              <div
                style={dashboardStyle.RiskCountAlignStyle}
                data-test={"dashboard_filter_rating"}
              >
                <DropDownListButton
                    DropDownButtonStyle={{ width: "11.25rem" }}
                  data={this.state.ratingFilterList}
                  value={this.state.ratingFilterList[2]}
                  onChange={this.onRatingChange}
                />
              </div>
            )}
            {this.state.filterList[0] !== 0 && this.state.filterList[0] !== 1 && (
              <div
                style={dashboardStyle.RiskCountAlignStyle}
                data-test={"dashboard__filter_type"}
              >
                <DropDownListButton
                  data={this.state.typeFilterList}
                  value={this.state.typeFilterList[2]}
                  onChange={this.onTypeChange}
                />
              </div>
            )}
              {this.state.filterList[0] !== 0 && this.state.filterList[0] !== 1 && (
                  <div
                      style={dashboardStyle.RiskCountAlignStyle}
                      data-test={"dashboard__filter_type"}
                  >
                      <DropDownListButton
                          DropDownButtonStyle={{ width: "11.25rem" }}
                          data={["ABC Pte Ltd", "Samurai Rocket"]}
                          value={"ABC Pte Ltd"}
                          onChange={this.onTypeChange}
                      />
                  </div>
              )}
          </div>

          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <divv style={{ ...dashboardStyle.RiskTowerStyle, paddingLeft: 0 }}>
              {displayData.map((data) => {
                return (
                  <div style={dashboardStyle.RiskTowerDivStyle}>
                    <ABCDemoRiskTower
                      style={{ display: "inline-flex" }}
                      category={data.label}
                      onHoverCallback={riskTileHoverCallback}
                      onUnhoverCallback={riskTileUnhoverCallback}
                      riskList={data.risks}
                      RiskTileClick={this.HandleRiskTileClick}
                    />
                  </div>
                );
              })}
            </divv>
          </div>
          <div style={{ height: "3.125rem" }} />
        </div>
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
      </div>
    );
  }
  onSearchCallBack = (e) => {
    let filterList = this.state.filterList;
    filterList[2] = e.target.value;
    this.setState({ filterList: filterList });
  };

  onTierChange = (event) => {
    let tier = event.value;
    let tierIndex = this.state.tierFilterList.findIndex((el) => {
      return el === tier;
    });
    let filterList = this.state.filterList;
    filterList[0] = tierIndex;
    this.setState({ filterList: filterList });
  };
  onRatingChange = (event) => {
    let rating = event.value;
    let ratingIndex = this.state.ratingFilterList.findIndex((el) => {
      return el === rating;
    });
    let filterList = this.state.filterList;
    filterList[1] = 5 - ratingIndex;
    this.setState({ filterList: filterList });
  };
  onTypeChange = (event) => {
    let type = event.value;
    let typeIndex = this.state.typeFilterList.findIndex((el) => {
      return el === type;
    });
    let filterList = this.state.filterList;
    filterList[3] = typeIndex;
    this.setState({ filterList: filterList });
  };
  TypeMatching = (risk) => {
    return (
      this.state.filterList[3] === 0 ||
      risk[this.state.typeList[this.state.filterList[3] - 1].name]
    );
  };
  filterData = (riskList) => {
    let displayData = riskList.filter((risk) => {
      return (
        risk.level ===
        this.state.tierFilterList[this.state.filterList[0]].toLowerCase()
      );
    });
    return displayData;
  };
}

const BlurEffectStyle = {
  filter: "blur(0.625rem)",
};

export { ABCDemoRiskEdit };
