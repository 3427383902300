export const fakeRiskListData = [
  {
    category: "western_europe",
    level: "global",
    label: "Western Europe",
    risks: [
      {
        riskNumber: "WE01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "12",
        owner:[]
      },
      {
        riskNumber: "WE02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "1",
        owner:[]
      },
      {
        riskNumber: "WE03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "4",
        owner:[]
      },
    ],
  },
  {
    category: "central_and_eastern_europe",
    level: "global",
    label: "Central and Eastern Europe",
    risks: [
      {
        riskNumber: "CEE01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "4",
        owner:[]
      },
      {
        riskNumber: "CEE02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "1",
        owner:[]
      },
      {
        riskNumber: "CEE03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "1",
        owner:[]
      },
    ],
  },
  {
    category: "asia",
    level: "global",
    label: "Asia",
    risks: [
      {
        riskNumber: "AS01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "15",
        owner:[]
      },
      {
        riskNumber: "AS02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "5",
        owner:[]
      },
      {
        riskNumber: "AS03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "20",
        owner:[]
      },
    ],
  },
  {
    category: "mediterranean_middle_east",
    level: "global",
    label: "Mediterranean & Middle East",
    risks: [
      {
        riskNumber: "MME01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "3",
        owner:[]
      },
      {
        riskNumber: "MME02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "11",
        owner:[]
      },
      {
        riskNumber: "MME03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "6",
        owner:[]
      },
    ],
  },
  {
    category: "americas",
    level: "global",
    label: "Americas",
    risks: [
      {
        riskNumber: "AM01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "7",
        owner:[]
      },
      {
        riskNumber: "AM02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "3",
        owner:[]
      },
      {
        riskNumber: "AM03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "2",
        owner:[]
      },
    ],
  },
  {
    category: "africa",
    level: "global",
    label: "Africa",
    risks: [
      {
        riskNumber: "AF01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "8",
        owner:[]
      },
      {
        riskNumber: "AF02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "6",
        owner:[]
      },
      {
        riskNumber: "AF03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "0",
        owner:[]
      },
    ],
  },
  {
    category: "east_asia",
    level: "region",
    label: "East Asia",
    risks: [
      {
        riskNumber: "EA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "5",
        owner:[]
      },
      {
        riskNumber: "EA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "1",
        owner:[]
      },
      {
        riskNumber: "EA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "4",
        owner:[]
      },
    ],
  },
  {
    category: "south_asia",
    level: "region",
    label: "South Asia",
    risks: [
      {
        riskNumber: "SA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "2",
        owner:[]
      },
      {
        riskNumber: "SA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "0",
        owner:[]
      },
      {
        riskNumber: "SA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "1",
        owner:[]
      },
    ],
  },
  {
    category: "south_east_asia",
    level: "region",
    label: "South East Asia",
    risks: [
      {
        riskNumber: "SEA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "7",
        owner:[]
      },
      {
        riskNumber: "SEA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "2",
        owner:[]
      },
      {
        riskNumber: "SEA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "6",
        owner:[]
      },
    ],
  },
  {
    category: "central_asia",
    level: "region",
    label: "Central Asia",
    risks: [
      {
        riskNumber: "CA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "1",
        owner:[]
      },
      {
        riskNumber: "CA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "0",
        owner:[]
      },
      {
        riskNumber: "CA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "3",
        owner:[]
      },
    ],
  },
  {
    category: "west_asia",
    level: "region",
    label: "West Asia",
    risks: [
      {
        riskNumber: "WA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "0",
        owner:[]
      },
      {
        riskNumber: "WA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "0",
        owner:[]
      },
      {
        riskNumber: "WA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "6",
        owner:[]
      },
    ],
  },
  {
    category: "strategic",
    level: "local",
    label: "Strategic",
    risks: [],
  },
  {
    category: "operational",
    level: "local",
    label: "Operational",
    risks: [],
  },
  {
    category: "financial",
    level: "local",
    label: "Financial",
    risks: [
      {
        riskNumber: "WA01",
        name: "link_analysis",
        label: "Link Analysis",
        alerts: "6",
        owner:[]
      },
      {
        riskNumber: "WA02",
        name: "third_party_payment",
        label: "Third-party Payment",
        alerts: "1",
        owner:[]
      },
      {
        riskNumber: "WA03",
        name: "travel_entertainment",
        label: "Travel & Entertainment",
        alerts: "3",
        owner:[]
      },
    ],
  },
  {
    category: "technology",
    level: "local",
    label: "Technology",
    risks: [],
  },
  {
    category: "compliance",
    level: "local",
    label: "Compliance",
    risks: [],
  },
];
