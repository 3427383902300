import React from "react";
import {
  mapping,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  IconSVG,
  NameCard,
  DropDownListButton,
  ButtonSolid,
  NonValidationInput,
  AutoSizeTextArea,
  riskEditStyle,
} from "@aim-mf/styleguide";

class KRIIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.props);
    return (
      <div style={riskEditStyle.followingCardPadding}>
        <div style={riskEditStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.KRIs.length !== 0 &&
              this.props.KRIs.map((kri, index) => {
                if (this.props.rights.edit === 1) {
                  return (
                    <div key={kri.id} style={{ margin: "1.25rem" }}>
                      <KRICard
                        kri={kri}
                        index={index}
                        handleKRIDescriptionChange={
                          this.handleKRIDescriptionChange
                        }
                        handleKRIOwnerChange={this.handleKRIOwnerChange}
                        handleKRIFrequencyChange={this.handleKRIFrequencyChange}
                        handleKRICategoryChange={this.handleKRICategoryChange}
                        handleKRITypeChange={this.handleKRITypeChange}
                        handleKRILowerThresholdChange={
                          this.handleKRILowerThresholdChange
                        }
                        handleKRIUpperThresholdChange={
                          this.handleKRIUpperThresholdChange
                        }
                        handleKRIThresholdUnitChange={
                          this.handleKRIThresholdUnitChange
                        }
                        handleKRIQualiLabel1Change={
                          this.handleKRIQualiLabel1Change
                        }
                        handleKRIQualiLabel2Change={
                          this.handleKRIQualiLabel2Change
                        }
                        handleKRIQualiLabel3Change={
                          this.handleKRIQualiLabel3Change
                        }
                        handleDelete={this.handleDelete}
                        handleKRIEntry={this.props.handleKRIEntry}
                        handleKRISafeZoneChange={this.handleKRISafeZoneChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={kri.id} style={{ margin: "1.25rem" }}>
                      <KRICardView kri={kri} />
                    </div>
                  );
                }
              })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new KRI"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingKRIs = this.props.KRIs;
    let existingKRIsNumber = existingKRIs.map((KRI) => {
      return parseInt(KRI.number.slice(1));
    });
    if (existingKRIsNumber.length === 0) {
      existingKRIsNumber.push(0);
    }
    let newKRINumber = "K" + (Math.max(...existingKRIsNumber) + 1);
    let newKRI = {
      number: newKRINumber,
      description: "\u00A0",
      owner: [],
      frequency: "Annually",
      KRI_type: "Manual",
      lower_threshold: "0",
      upper_threshold: "100",
      threshold_unit: "%",
      safe_zone: "low",
      category: "Quantitative",
      qualilabel1: "green",
      qualilabel2: "amber",
      qualilabel3: "red",
    };
    existingKRIs.push(newKRI);
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIDescriptionChange = (value, index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs[index].description = value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };

  handleKRIFrequencyChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].frequency = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRITypeChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].KRI_type = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };

  // TODO: need to finish data update on threshold later
  handleKRISafeZoneChange = (index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs[index].safe_zone =
      existingKRIs[index].safe_zone === "low" ? "high" : "low";
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRICategoryChange = (index) => {
    let existingKRIs = this.props.KRIs;
    if (existingKRIs[index].category === "Qualitative") {
      existingKRIs[index].safe_zone = "low";
      existingKRIs[index].lower_threshold = 30;
      existingKRIs[index].upper_threshold = 50;
      existingKRIs[index].category = "Quantitative";
    } else {
      existingKRIs[index].safe_zone = "high";
      existingKRIs[index].lower_threshold = 1;
      existingKRIs[index].upper_threshold = 1;
      existingKRIs[index].category = "Qualitative";
    }
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIQualiLabel1Change = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].qualilabel1 = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIQualiLabel2Change = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].qualilabel2 = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIQualiLabel3Change = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].qualilabel3 = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRILowerThresholdChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].lower_threshold = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIUpperThresholdChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].upper_threshold = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIThresholdUnitChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].threshold_unit = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleDelete = (index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs.splice(index, 1);
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIOwnerChange = (index) => {
    this.props.handleAddUser(
      "KRIs",
      index,
      this.props.KRIs[index].owner,
      "Assign Key Risk Indicator Owner"
    );
  };
}

const KRICard = (props) => {
  return (
    <div style={riskEditStyle.KRICardStyle}>
      {/*top right tool bar*/}
      <div
        style={{
          position: "absolute",
          height: "0px",
          display: "inline-flex",
          right: 0,
          margin: "0.5em",
        }}
      >
        <ButtonSolid
          height={riskEditStyle.KRIValueEntryButtonHeight}
          width={riskEditStyle.KRIValueEntryButtonWidth}
          name={"value entry"}
          clickEvent={() => {
            props.handleKRIEntry(props.kri);
          }}
        />
        <div style={{ width: "0.625rem" }} />
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
          size={1}
        />
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <KRINumber KRINumber={props.kri.number} />
          <KRIDescription
            description={props.kri.description}
            index={props.index}
            handleKRIDescriptionChange={props.handleKRIDescriptionChange}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "96%",
            justifyContent: "space-between",
          }}
        >
          <KRIOwner
            KRIOwner={props.kri.owner}
            handleKRIOwnerChange={props.handleKRIOwnerChange}
            index={props.index}
          />
          <KRIFrequency
            handleKRIFrequencyChange={props.handleKRIFrequencyChange}
            frequency={props.kri.frequency}
            frequencyList={[
              "Annually",
              "Quarterly",
              "Monthly",
              "Daily",
              "Ad-hoc",
            ]}
            index={props.index}
          />
          <KRIType
            handleKRITypeChange={props.handleKRITypeChange}
            type={props.kri.KRI_type}
            typeList={["Automatic", "Manual"]}
            index={props.index}
          />
          <KRIThreshold
            currentUnit={props.kri.threshold_unit}
            unitList={["%", ".00"]}
            KRILow={props.kri.lower_threshold}
            KRIHigh={props.kri.upper_threshold}
            index={props.index}
            safeZone={props.kri.safe_zone}
            category={props.kri.category}
            qualiLabel1={props.kri.qualilabel1}
            qualiLabel2={props.kri.qualilabel2}
            qualiLabel3={props.kri.qualilabel3}
            handleKRIQualiLabel1Change={props.handleKRIQualiLabel1Change}
            handleKRIQualiLabel2Change={props.handleKRIQualiLabel2Change}
            handleKRIQualiLabel3Change={props.handleKRIQualiLabel3Change}
            handleKRILowerThresholdChange={props.handleKRILowerThresholdChange}
            handleKRIUpperThresholdChange={props.handleKRIUpperThresholdChange}
            handleKRIThresholdUnitChange={props.handleKRIThresholdUnitChange}
            handleKRISafeZoneChange={props.handleKRISafeZoneChange}
            handleKRICategoryChange={props.handleKRICategoryChange}
          />
        </div>
      </div>
    </div>
  );
};
const KRINumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>NO.</div>
      <div style={riskEditStyle.contentTextStyle}>{props.KRINumber}</div>
    </div>
  );
};
const KRIDescription = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextInput
          content={props.description}
          onCustomChange={props.handleKRIDescriptionChange}
          index={props.index}
        />
      </div>
    </div>
  );
};
const KRIOwner = (props) => {
  const OwnerChange = () => {
    props.handleKRIOwnerChange(props.index);
  };
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>KRI owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.KRIOwner.length !== 0 &&
          props.KRIOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={riskEditStyle.buttonHeight}
        width={riskEditStyle.buttonWidth}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const KRIFrequency = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.frequency}
          data={props.frequencyList}
          CustomOnChange={props.handleKRIFrequencyChange}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
const KRIType = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.type}
          data={props.typeList}
          CustomOnChange={props.handleKRITypeChange}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
const KRIThreshold = (props) => {
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div style={riskEditStyle.sectionTitleStyle}>Thresholds</div>
        <div className={"div_hover"}>
          <IconSVG
            name={"flip"}
            color={mapping["Color_Basic_Primary"]}
            wrapperStyle={{ position: "absolute", top: 0, right: 22 }}
            onClick={() => {
              props.handleKRISafeZoneChange(props.index);
            }}
            size={1}
          />
        </div>
        <div className={"div_hover"}>
          <IconSVG
            name={"vSwap"}
            color={mapping["Color_Basic_Primary"]}
            wrapperStyle={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              props.handleKRICategoryChange(props.index);
            }}
            size={1}
          />
        </div>
      </div>
      {props.category === "Qualitative" && (
        <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
          <ThresholdQualiLabel1Input
            qualiLabel1={props.qualiLabel1}
            handleKRIQualiLabel1Change={props.handleKRIQualiLabel1Change}
            index={props.index}
          />
          <ThresholdQualiLabel2Input
            qualiLabel2={props.qualiLabel2}
            handleKRIQualiLabel2Change={props.handleKRIQualiLabel2Change}
            index={props.index}
          />
          <ThresholdQualiLabel3Input
            qualiLabel3={props.qualiLabel3}
            handleKRIQualiLabel3Change={props.handleKRIQualiLabel3Change}
            index={props.index}
          />
        </div>
      )}
      {props.category !== "Qualitative" && (
        <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
          <DropDownListButton
            index={props.index}
            value={props.currentUnit}
            data={props.unitList}
            CustomOnChange={props.handleKRIThresholdUnitChange}
            DropDownButtonStyle={
              riskEditStyle.riskThresholdTypeDropDownButtonStyle
            }
            disabled={props.disabled}
          />
          <ThresholdLowerInput
            unit={props.currentUnit}
            index={props.index}
            lower={props.KRILow}
            safeZone={props.safeZone}
            handleKRILowerThresholdChange={props.handleKRILowerThresholdChange}
          />
          <ThresholdMiddleRange
            unit={props.currentUnit}
            lower={props.KRILow}
            upper={props.KRIHigh}
          />
          <ThresholdUpperInput
            unit={props.currentUnit}
            index={props.index}
            upper={props.KRIHigh}
            safeZone={props.safeZone}
            handleKRIUpperThresholdChange={props.handleKRIUpperThresholdChange}
          />
        </div>
      )}
    </div>
  );
};
const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={riskEditStyle.KRIInputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const ThresholdQualiLabel1Input = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <NonValidationInput
        fieldstyle={{
          ...riskEditStyle.ThresholdSafeInputFieldStyle,
          width: "6rem",
        }}
        value={props.qualiLabel1}
        onChange={props.handleKRIQualiLabel1Change}
        index={props.index}
      />
    </div>
  );
};
const ThresholdQualiLabel2Input = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <NonValidationInput
        fieldstyle={{
          ...riskEditStyle.ThresholdMidInputFieldStyle,
          width: "6rem",
        }}
        value={props.qualiLabel2}
        onChange={props.handleKRIQualiLabel2Change}
        index={props.index}
      />
    </div>
  );
};
const ThresholdQualiLabel3Input = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <NonValidationInput
        fieldstyle={{
          ...riskEditStyle.ThresholdDangerInputFieldStyle,
          width: "6rem",
        }}
        value={props.qualiLabel3}
        onChange={props.handleKRIQualiLabel3Change}
        index={props.index}
      />
    </div>
  );
};
const ThresholdLowerInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "low"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        onChange={props.handleKRILowerThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};
const ThresholdMiddleRange = (props) => {
  return (
    <div style={riskEditStyle.thresholdMiddleStyle}>
      {props.lower}
      {props.unit === "%" ? "%" : ""}-{props.upper}
      {props.unit === "%" ? "%" : ""}
    </div>
  );
};
const ThresholdUpperInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "high"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        onChange={props.handleKRIUpperThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};

const KRICardView = (props) => {
  return (
    <div style={riskEditStyle.KRICardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            paddingTop: 0,
            display: "inline-flex",
          }}
        >
          <KRINumber KRINumber={props.kri.number} />
          <KRIDescriptionView description={props.kri.description} />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <KRIOwnerView KRIOwner={props.kri.owner} />
          <KRIFrequencyView
            frequency={props.kri.frequency}
            frequencyList={["Annually", "Quarterly", "Monthly", "Daily"]}
          />
          <KRITypeView
            type={props.kri.KRI_type}
            typeList={["Automatic", "Manual"]}
          />
          <KRIThresholdView
            safeZone={props.kri.safe_zone}
            currentUnit={props.kri.threshold_unit}
            unitList={["%", ".00"]}
            KRILow={props.kri.lower_threshold}
            KRIHigh={props.kri.upper_threshold}
          />
        </div>
      </div>
    </div>
  );
};
const KRIDescriptionView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const KRIOwnerView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>KRI owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.KRIOwner.length !== 0 &&
          props.KRIOwner.map((owner) => {
            // console.log(owner)
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const KRIFrequencyView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const KRITypeView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const KRIThresholdView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Thresholds</div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.currentUnit}
          data={props.unitList}
          DropDownButtonStyle={
            riskEditStyle.riskThresholdTypeDropDownButtonStyle
          }
          disabled={true}
        />
        <ThresholdLowerInputView
          safeZone={props.safeZone}
          unit={props.currentUnit}
          lower={props.KRILow}
        />

        <ThresholdMiddleRange
          unit={props.currentUnit}
          lower={props.KRILow}
          upper={props.KRIHigh}
        />
        <ThresholdUpperInputView
          safeZone={props.safeZone}
          unit={props.currentUnit}
          upper={props.KRIHigh}
        />
      </div>
    </div>
  );
};

const ThresholdLowerInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "low"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        disabled={true}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};
const ThresholdUpperInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "high"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        disabled={true}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};

const TextContent = (props) => {
  return (
    <div style={riskEditStyle.KRITextWrapperStyle}>
      <div style={riskEditStyle.TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const CustomizedSizeTextContent = (props) => {
  return (
    <div
      style={{
        ...riskEditStyle.TextWrapperStyle,
        width: props.width,
        height: props.height,
      }}
    >
      <div style={{ ...riskEditStyle.TextStyle, paddingTop: 0 }}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const riskTierDropDownButtonStyle = {
  width: "7rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
export { KRIIndicator };
