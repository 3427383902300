import React from "react";
import {
  mapping,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  IconSVG,
  NameCard,
  CustomizedCheckBox,
  NonValidationInput,
  DropDownListButton,
  ButtonSolid,
  Pill,
  AutoSizeTextArea,
  SearchAutoComplete,
  riskEditStyle,
} from "@aim-mf/styleguide";

class ExistingControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={riskEditStyle.followingCardPadding}>
        <div style={riskEditStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.controls.length !== 0 &&
              this.props.controls
                .sort((a, b) => {
                  return (
                    parseInt(a.number.substr(1).trim()) <
                    parseInt(b.number.substr(1).trim())
                  );
                })
                .map((control, index) => {
                  if (this.props.rights.edit === 1) {
                    return (
                      <div key={index} style={{ margin: "1.25rem" }}>
                        <ControlCard
                          index={index}
                          control={control}
                          handleControlDescriptionChange={
                            this.handleControlDescriptionChange
                          }
                          handleControlOwnerChange={
                            this.handleControlOwnerChange
                          }
                          handleControlFrequencyChange={
                            this.handleControlFrequencyChange
                          }
                          handleControlTypeChange={this.handleControlTypeChange}
                          handleControlStrengthChange={
                            this.handleControlStrengthChange
                          }
                          handleDelete={this.handleDelete}
                          controlsList={this.props.controlsList}
                          controlSearchIndex={this.props.controlSearchIndex}
                          handleSearchGroupRisk={
                            this.props.handleSearchControls
                          }
                          handleriskDetailRequest={
                            this.props.handleControlDetailRequest
                          }
                          handleSourceChange={this.handleSourceChange}
                          searching={this.props.searching}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} style={{ margin: "1.25rem" }}>
                        <ControlCardView control={control} />
                      </div>
                    );
                  }
                })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Control"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingControls = this.props.controls;
    let existingControlsNumber = existingControls.map((control) => {
      return parseInt(control.number.slice(1));
    });

    if (existingControlsNumber.length === 0) {
      existingControlsNumber.push(0);
    }
    let newControlNumber = "C" + (Math.max(...existingControlsNumber) + 1);
    let newControl = {
      number: newControlNumber,
      description: "",
      owner: [],
      frequency: "Annually",
      control_type: "Automatic",
      strength: "Weak",
      ia_assurance_strength: null,
      management_assurance_strength: null,
      other_assurance_strength: null,
    };
    existingControls.push(newControl);
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlDescriptionChange = (value, index) => {
    let existingControls = this.props.controls;
    existingControls[index].description = value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlFrequencyChange = (event) => {
    let existingControls = this.props.controls;
    let index = event.target.props.index;
    existingControls[index].frequency = event.value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlTypeChange = (event) => {
    let existingControls = this.props.controls;
    let index = event.target.props.index;
    existingControls[index].control_type = event.value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlStrengthChange = (index, strength, type) => {
    let existingControls = this.props.controls;
    if (existingControls[index][type] === strength) {
      existingControls[index][type] = "Not Assessed";
    } else {
      existingControls[index][type] = strength;
    }
    // update overall control strength
    let overallStrength = this.getOverallStrength(existingControls[index]);
    existingControls[index].strength = overallStrength;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };

  getOverallStrength = (control) => {
    if (
      control.management_assurance_strength === null ||
      control.ia_assurance_strength === null ||
      control.other_assurance_strength === null
    )
      return "Weak";
    if (
      control.management_assurance_strength === "Not Assessed" ||
      control.ia_assurance_strength === "Not Assessed" ||
      control.other_assurance_strength === "Not Assessed"
    )
      return "Weak";
    if (
      control.management_assurance_strength === "Weak" ||
      control.ia_assurance_strength === "Weak" ||
      control.other_assurance_strength === "Weak"
    )
      return "Weak";
    if (
      control.management_assurance_strength === "Moderate" ||
      control.ia_assurance_strength === "Moderate" ||
      control.other_assurance_strength === "Moderate"
    )
      return "Moderate";
    if (
      control.management_assurance_strength === "Strong" ||
      control.ia_assurance_strength === "Strong" ||
      control.other_assurance_strength === "Strong"
    )
      return "Strong";
  };
  handleDelete = (index) => {
    let existingControls = this.props.controls;
    existingControls.splice(index, 1);
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleSourceChange = (index, type) => {
    let existingControls = this.props.controls;
    existingControls[index][type] = !existingControls[index][type];
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlOwnerChange = (index) => {
    this.props.handleAddUser(
      "controls",
      index,
      this.props.controls[index].owner,
      "Assign Control Owner"
    );
  };
}

const ControlCard = (props) => {
  return (
    <div style={riskEditStyle.controlCardStyle}>
      {/*top right tool bar*/}
      <div style={riskEditStyle.controlDeleteIcon}>
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
          size={1}
        />
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ControlNumber controlNumber={props.control.number} />
          <ControlDescription
            controlsList={props.controlsList}
            controlSearchIndex={props.controlSearchIndex}
            description={props.control.description}
            handleControlDescriptionChange={
              props.handleControlDescriptionChange
            }
            index={props.index}
            handleSearchGroupRisk={props.handleSearchGroupRisk}
            handleRiskDetailRequest={props.handleriskDetailRequest}
            searching={props.searching}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <ControlOwner
            controlOwner={props.control.owner}
            index={props.index}
            handleControlOwnerChange={props.handleControlOwnerChange}
          />
          <ControlFrequency
            handleControlFrequencyChange={props.handleControlFrequencyChange}
            frequency={props.control.frequency}
            frequencyList={[
              "Annually",
              "Quarterly",
              "Monthly",
              "Daily",
              "Ad-hoc",
            ]}
            index={props.index}
          />
          <ControlType
            handleControlTypeChange={props.handleControlTypeChange}
            type={props.control.control_type}
            typeList={["Automatic", "Manual"]}
            index={props.index}
          />
          {/*<ControlStrength*/}
          {/*  strength={props.control.strength}*/}
          {/*  index={props.index}*/}
          {/*  handleControlStrengthChange={props.handleControlStrengthChange}*/}
          {/*/>*/}
          <ControlSource
            ia_assurance_strength={props.control.ia_assurance_strength}
            management_assurance_strength={
              props.control.management_assurance_strength
            }
            other_assurance_strength={props.control.other_assurance_strength}
            handleControlStrengthChange={props.handleControlStrengthChange}
            managementSource={props.control.is_management_assurance}
            iaSource={props.control.is_ia_assurance}
            otherSource={props.control.is_other_assurance}
            index={props.index}
            handleSourceChange={props.handleSourceChange}
          />
        </div>
      </div>
    </div>
  );
};
const ControlNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>NO.</div>
      <div style={riskEditStyle.contentTextStyle}>{props.controlNumber}</div>
    </div>
  );
};
const ControlDescription = (props) => {
  const handleControlSearch = (searchContent) => {
    props.handleSearchGroupRisk(searchContent, props.index);
    props.handleControlDescriptionChange(searchContent, props.index);
  };
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ marginTop: "1.25rem" }}>
        {props.description.length < 120 && (
          <SearchAutoComplete
            // data ternary function is to only enable dropdown for current autocomplete
            inputFieldStyle={riskEditStyle.controlInputFieldStyle}
            data={
              props.controlSearchIndex === props.index
                ? props.controlsList.map(({ id, description }) => ({
                    id,
                    description,
                  }))
                : []
            }
            value={props.description}
            filterKey={"description"}
            handleSearchGroupRisk={handleControlSearch}
            handleriskDetailRequest={props.handleRiskDetailRequest}
            searching={props.searching}
          />
        )}
        {props.description.length >= 120 && (
          <AutoSizeTextArea
            fieldstyle={riskEditStyle.controlMultilineInputFieldStyle}
            value={props.description}
            CustomizedOnChange={(value) => {
              props.handleControlDescriptionChange(value, props.index);
            }}
          />
        )}
      </div>
    </div>
  );
};
const ControlOwner = (props) => {
  const OwnerChange = () => {
    props.handleControlOwnerChange(props.index);
  };
  return (
    <div style={riskEditStyle.controlOwnerWrapper}>
      <div style={riskEditStyle.sectionTitleStyle}>control owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.controlOwner.length !== 0 &&
          props.controlOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={riskEditStyle.buttonHeight}
        width={riskEditStyle.buttonWidth}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const ControlFrequency = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          CustomOnChange={props.handleControlFrequencyChange}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={props.disabled}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ControlType = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          CustomOnChange={props.handleControlTypeChange}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={props.disabled}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ControlStrength = (props) => {
  return (
    <div style={{ display: "inline-flex", paddingTop: "0.3125rem" }}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          props.handleControlStrengthChange(props.index, "Strong", props.type);
        }}
      >
        <Pill
          pillStyle={{ marginRight: "1rem" }}
          contentStyle={{
            color: props.strength === "Strong" ? "#FFFFFFD0" : "#FFFFFF3B",
          }}
          content={"Strong"}
          color={
            mapping["Color_Platform_RiskLow"] +
            (props.strength === "Strong" ? "66" : "3B")
          }
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          props.handleControlStrengthChange(
            props.index,
            "Moderate",
            props.type
          );
        }}
      >
        <Pill
          pillStyle={{ marginRight: "1rem" }}
          contentStyle={{
            color: props.strength === "Moderate" ? "#FFFFFFD0" : "#FFFFFF3B",
          }}
          content={"Moderate"}
          color={
            mapping["Color_Platform_RiskMed"] +
            (props.strength === "Moderate" ? "66" : "3B")
          }
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          props.handleControlStrengthChange(props.index, "Weak", props.type);
        }}
      >
        <Pill
          contentStyle={{
            color: props.strength === "Weak" ? "#FFFFFFD0" : "#FFFFFF3B",
          }}
          content={"Weak"}
          color={
            mapping["Color_Platform_RiskHigh"] +
            (props.strength === "Weak" ? "66" : "3B")
          }
        />
      </div>
    </div>
  );
};
const ControlSource = (props) => {
  return (
    <div style={{ paddingRight: "2rem", width: "31.25rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Control Strength</div>
      <div style={{ height: "1.25rem" }} />
      <div style={{ display: "inline-flex", paddingBottom: "0.625rem" }}>
        <div style={riskEditStyle.ControlSourceFont}>First Line of Defence</div>

        <ControlStrength
          strength={props.management_assurance_strength}
          index={props.index}
          handleControlStrengthChange={props.handleControlStrengthChange}
          type={"management_assurance_strength"}
        />
      </div>
      <div style={{ display: "inline-flex", paddingBottom: "0.625rem" }}>
        <div style={riskEditStyle.ControlSourceFont}>
          Second Line of Defence
        </div>

        <ControlStrength
          strength={props.ia_assurance_strength}
          index={props.index}
          handleControlStrengthChange={props.handleControlStrengthChange}
          type={"ia_assurance_strength"}
        />
      </div>
      <div style={{ display: "inline-flex", paddingBottom: "0.625rem" }}>
        <div style={riskEditStyle.ControlSourceFont}>Third Line of Defence</div>

        <ControlStrength
          strength={props.other_assurance_strength}
          index={props.index}
          handleControlStrengthChange={props.handleControlStrengthChange}
          type={"other_assurance_strength"}
        />
      </div>
    </div>
  );
};
const ControlSourceCheckbox = (props) => {
  return (
    <div>
      <div style={{ display: "inline-flex", margin: "0.5em" }}>
        <CustomizedCheckBox
          value={props.value}
          checkBoxDivStyle={{ lineHeight: 0 }}
          onCheckChange={props.onCheck}
          disabled={props.disabled}
        />
        <div style={riskEditStyle.CheckBoxLabelStyle}>{props.label}</div>
      </div>
    </div>
  );
};
const ControlCardView = (props) => {
  return (
    <div style={riskEditStyle.controlCardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ControlNumber controlNumber={props.control.number} />
          <ControlDescriptionView description={props.control.description} />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <ControlOwnerView controlOwner={props.control.owner} />
          <ControlFrequencyView
            frequency={props.control.frequency}
            frequencyList={[
              "Annually",
              "Quarterly",
              "Monthly",
              "Daily",
              "Ad-hoc",
            ]}
          />
          <ControlTypeView
            type={props.control.control_type}
            typeList={["Automatic", "Manual"]}
          />
          <ControlSource
            disabled={true}
            managementSource={props.control.is_management_assurance}
            iaSource={props.control.is_ia_assurance}
            otherSource={props.control.is_other_assurance}
            index={props.index}
            handleSourceChange={props.handleSourceChange}
          />
        </div>
      </div>
    </div>
  );
};
const ControlDescriptionView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const ControlOwnerView = (props) => {
  return (
    <div style={riskEditStyle.controlOwnerWrapper}>
      <div style={riskEditStyle.sectionTitleStyle}>control owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.controlOwner.length !== 0 &&
          props.controlOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const ControlFrequencyView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const ControlTypeView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const ControlStrengthView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>control strength</div>
      <div style={{ paddingTop: "1.5rem", display: "inline-flex" }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Strong" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Strong"}
            color={
              mapping["Color_Platform_RiskLow"] +
              (props.strength === "Strong" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Moderate" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Moderate"}
            color={
              mapping["Color_Platform_RiskMed"] +
              (props.strength === "Moderate" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            contentStyle={{
              color: props.strength === "Weak" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Weak"}
            color={
              mapping["Color_Platform_RiskHigh"] +
              (props.strength === "Weak" ? "66" : "3B")
            }
          />
        </div>
      </div>
    </div>
  );
};

const ControlSourceView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Source of Assurance</div>
      <ControlSourceCheckbox
        value={props.managementSource}
        label={"Management Assurance"}
        disabled={true}
      />

      <ControlSourceCheckbox
        value={props.iaSource}
        label={"Internal Audit Assurance"}
        disabled={true}
      />
      <ControlSourceCheckbox
        value={props.otherSource}
        label={"Other Assurance"}
        disabled={true}
      />
    </div>
  );
};
const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={riskEditStyle.controlInputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={riskEditStyle.controlTextWrapperStyle}>
      <div style={riskEditStyle.TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};

export { ExistingControl };
