import React from "react";
import {
  mapping,
  DropDownListButton,
  RiskEditCardHeader,
  NameCard,
  ButtonSolid,
  NonValidationInput,
  NonValidationTextInput,
  AutoSizeTextArea,
  RiskEditCardAddNew,
  IconSVG,
  riskEditStyle,
} from "@aim-mf/styleguide";

class RiskAppetite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={riskEditStyle.RiskAppetiteStyle}>
        <div style={riskEditStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          <div style={{ margin: "1.25rem" }}>
            {this.props.rights.edit === 1 ? (
              <RiskAppetiteContent
                riskAppetite={this.props.riskAppetite}
                riskAppetiteLevel={this.props.riskAppetiteLevel}
                riskAppetiteStatement={this.props.riskAppetiteStatement}
                handleAppetiteStatementChange={
                  this.handleAppetiteStatementChange
                }
                handleAppetiteLevelChange={this.handleAppetiteLevelChange}
                handleAppetiteMetricChange={this.handleAppetiteMetricChange}
                handleAppetiteResultChange={this.handleAppetiteResultChange}
                handleAppetiteProviderChange={this.handleAppetiteProviderChange}
                handleAppetiteChange={this.handleAppetiteChange}
                handleRemove={this.handleRemove}
                handleRiskAppetiteSafeZoneChange={
                  this.handleRiskAppetiteSafeZoneChange
                }
              />
            ) : (
              <RiskAppetiteContentView
                riskAppetite={this.props.riskAppetite}
                riskAppetiteLevel={this.props.riskAppetiteLevel}
                riskAppetiteStatement={this.props.riskAppetiteStatement}
              />
            )}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Tolerance Metric"}
                width={"12rem"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  handleRiskAppetiteSafeZoneChange = (index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index].safe_zone =
      existingAppetite[index].safe_zone === "low" ? "high" : "low";
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAppetiteStatementChange = (value) => {
    this.props.handleRiskAppetiteStatementChange(value);
  };
  handleAppetiteLevelChange = (event) => {
    this.props.handleRiskAppetiteLevelChange(event.value);
  };
  handleAppetiteChange = (value, key, index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index][key] = value;
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAppetiteMetricChange = (value, index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index].risk_matrix = value;
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAppetiteResultChange = (value, index) => {
    //console.log(value, index);
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index].result = value;
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAddNew = () => {
    let existingAppetite = this.props.riskAppetite;
    let newNumber =
      "RA" +
      (Math.max(
        ...this.props.riskAppetite.map((a) => {
          return a.number.split("RA").pop();
        }),
        0
      ) +
        1);
    existingAppetite.push({
      lower_threshold: 50,
      number: newNumber,
      provided_by: [],
      risk_matrix: "",
      threshold_unit: "%",
      upper_threshold: 50,
      safe_zone: "low",
      result: 0,
    });
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleRemove = (index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite.splice(index, 1);
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };

  handleAppetiteProviderChange = (index) => {
    this.props.handleAddUser(
      "riskappetite",
      index,
      this.props.riskAppetite[index].provided_by,
      "Assign Appetite Owner"
    );
  };
}

const RiskAppetiteContent = (props) => {
  return (
    <div style={riskEditStyle.cardStyle}>
      <div>
        <div style={riskEditStyle.riskAppetiteStatementWrapperStyle}>
          <RiskAppetiteLevel
            handleAppetiteLevelChange={props.handleAppetiteLevelChange}
            level={props.riskAppetiteLevel}
            levelList={["Very High", "High", "Medium", "Low", "Very Low"]}
          />
          <RiskAppetiteRationale
            description={props.riskAppetiteStatement}
            handleAppetiteStatementChange={props.handleAppetiteStatementChange}
          />
        </div>
      </div>
      {props.riskAppetite.map((riskAppetite, index) => {
        return (
          <div style={riskEditStyle.cardBGStyle}>
            <IconSVG
              onClick={() => props.handleRemove(index)}
              name={"delete"}
              wrapperStyle={{ position: "absolute", right: "5px", top: "5px" }}
              color={mapping["Color_Basic_Mute_2"]}
              size={1}
            />
            <div style={riskEditStyle.riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteNumber
                riskAppetiteNumber={riskAppetite.number}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
              />

              <RiskAppetiteMetric
                matrix={riskAppetite.risk_matrix}
                handleAppetiteMetricChange={props.handleAppetiteMetricChange}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
              />
            </div>
            <div style={riskEditStyle.riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteProvider
                provider={riskAppetite.provided_by}
                handleAppetiteProviderChange={
                  props.handleAppetiteProviderChange
                }
                index={index}
              />
              <RiskAppetiteThreshold
                safe_zone={riskAppetite.safe_zone}
                currentUnit={riskAppetite.threshold_unit}
                unitList={["%", ".00"]}
                riskAppetiteLow={riskAppetite.lower_threshold}
                riskAppetiteHigh={riskAppetite.upper_threshold}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
                handleRiskAppetiteSafeZoneChange={
                  props.handleRiskAppetiteSafeZoneChange
                }
              />
              <RiskAppetiteResult
                resultValue={riskAppetite.result}
                handleAppetiteResultChange={props.handleAppetiteResultChange}
                currentUnit={riskAppetite.threshold_unit}
                index={index}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
const RiskAppetiteRationale = (props) => {
  // console.log(props.description);
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Appetite Rationale</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <AutoSizeTextArea
          fieldstyle={{
            ...riskEditStyle.MultilineInputFieldStyle,
            width: "49rem",
          }}
          value={props.description}
          CustomizedOnChange={props.handleAppetiteStatementChange}
        />
      </div>
    </div>
  );
};
const RiskAppetiteResult = (props) => {
  const onChange = (value) => {
    props.handleAppetiteResultChange(value, props.index);
  };
  return (
    <div style={{ marginRight: "3rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Result</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <div style={{ position: "relative" }}>
          <NonValidationInput
            fieldstyle={{
              ...riskEditStyle.ThresholdSafeInputFieldStyle,
              border: "None",
            }}
            value={props.resultValue}
            onChange={(event) => {
              onChange(parseInt(event.value));
            }}
          />
          {props.currentUnit === "%" && (
            <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
          )}
        </div>
      </div>
    </div>
  );
};
const RiskAppetiteProvider = (props) => {
  const OwnerChange = () => {
    props.handleAppetiteProviderChange(props.index);
  };
  return (
    <div style={{ marginRight: "2rem", width: "11rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Provided By</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.provider != null &&
          Object.keys(props.provider).length !== 0 &&
          props.provider.map((owner) => {
            return (
              <NameCard
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={riskEditStyle.buttonHeight}
        width={riskEditStyle.buttonWidth}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const RiskAppetiteLevel = (props) => {
  return (
    <div style={{ width: "11rem", marginRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Appetite Level</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.level}
          data={props.levelList}
          CustomOnChange={props.handleAppetiteLevelChange}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
        />
      </div>
    </div>
  );
};
const RiskAppetiteMetric = (props) => {
  const onChange = (value) => {
    props.handleAppetiteMetricChange(value, props.index);
  };
  return (
    <div style={{ marginRight: "3rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Tolerance Metric</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <AutoSizeTextArea
          fieldstyle={{
            ...riskEditStyle.MultilineInputFieldStyle,
            width: "58.2rem",
          }}
          value={props.matrix}
          CustomizedOnChange={onChange}
        />
      </div>
    </div>
  );
};
const RiskAppetiteThreshold = (props) => {
  const handleRiskAppetiteUnitChange = (event) => {
    props.handleAppetiteChange(event.value, "threshold_unit", props.index);
  };
  //since it is a single value, instead of change the database struct, put upper=lower to achieve the same effect
  const handleRiskAppetiteLowerThresholdChange = (event) => {
    props.handleAppetiteChange(event.value, "lower_threshold", props.index);
    props.handleAppetiteChange(event.value, "upper_threshold", props.index);
  };
  const handleRiskAppetiteHigherThresholdChange = (event) => {
    props.handleAppetiteChange(event.value, "upper_threshold", props.index);
  };
  return (
    <div style={{ marginRight: "6rem" }}>
      <div style={{ position: "relative" }}>
        <div style={riskEditStyle.sectionTitleStyle}>Tolerance limit</div>
        {/*<div className={"div_hover"}>*/}
        {/*  <IconSVG*/}
        {/*    name={"flip"}*/}
        {/*    color={mapping["Color_Basic_Primary"]}*/}
        {/*    wrapperStyle={{ position: "absolute", top: 0, right: 0 }}*/}
        {/*    onClick={() => {*/}
        {/*      props.handleRiskAppetiteSafeZoneChange(props.index);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.currentUnit}
          data={props.unitList}
          CustomOnChange={handleRiskAppetiteUnitChange}
          DropDownButtonStyle={
            riskEditStyle.riskThresholdTypeDropDownButtonStyle
          }
          disabled={props.disabled}
        />
        <ThresholdLowerInput
          safe_zone={props.safe_zone}
          unit={props.currentUnit}
          index={props.index}
          lower={props.riskAppetiteLow}
          handleRiskAppetiteLowerThresholdChange={
            handleRiskAppetiteLowerThresholdChange
          }
        />

        {/*<ThresholdMiddleRange*/}
        {/*  unit={props.currentUnit}*/}
        {/*  lower={props.riskAppetiteLow}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
        {/*<ThresholdUpperInput*/}
        {/*  safe_zone={props.safe_zone}*/}
        {/*  unit={props.currentUnit}*/}
        {/*  index={props.index}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*  handleRiskAppetiteHigherThresholdChange={*/}
        {/*    handleRiskAppetiteHigherThresholdChange*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    </div>
  );
};

const RiskAppetiteContentView = (props) => {
  return (
    <div style={riskEditStyle.cardStyle}>
      <div>
        <div style={riskEditStyle.riskAppetiteStatementWrapperStyle}>
          <RiskAppetiteLevelView
            level={props.riskAppetiteLevel}
            levelList={["High", "Medium", "Low"]}
          />
          <RiskAppetiteStatementView
            description={props.riskAppetiteStatement}
          />
        </div>
      </div>
      {props.riskAppetite.map((riskAppetite, index) => {
        return (
          <div>
            <div style={riskEditStyle.riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteNumber riskAppetiteNumber={riskAppetite.number} />
              <RiskAppetiteMetricView matrix={riskAppetite.risk_matrix} />
            </div>
            <div style={riskEditStyle.riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteProviderView provider={riskAppetite.provided_by} />
              <RiskAppetiteThresholdView
                currentUnit={riskAppetite.threshold_unit}
                riskAppetiteLow={riskAppetite.lower_threshold}
                riskAppetiteHigh={riskAppetite.upper_threshold}
              />
              <RiskAppetiteResultView
                resultValue={riskAppetite.result}
                handleAppetiteResultChange={props.handleAppetiteResultChange}
                currentUnit={riskAppetite.threshold_unit}
                index={index}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const RiskAppetiteResultView = (props) => {
  return (
    <div style={{ marginRight: "3rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Result</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <div style={{ position: "relative" }}>
          <NonValidationInput
            fieldstyle={{
              ...riskEditStyle.ThresholdSafeInputFieldStyle,
              border: "None",
            }}
            value={props.resultValue}
            disabled={true}
          />
          {props.currentUnit === "%" && (
            <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
          )}
        </div>
      </div>
    </div>
  );
};
const RiskAppetiteStatementView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Appetite Statement</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} width={"51.5rem"} />
      </div>
    </div>
  );
};
const RiskAppetiteProviderView = (props) => {
  return (
    <div style={{ marginRight: "2rem", width: "10rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Provided By</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.provider != null &&
          Object.keys(props.provider).length !== 0 &&
          props.provider.map((owner) => {
            return (
              <NameCard
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const RiskAppetiteLevelView = (props) => {
  return (
    <div style={{ width: "10rem", marginRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Appetite Level</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.level}
          data={props.levelList}
          DropDownButtonStyle={riskEditStyle.riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const RiskAppetiteMetricView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Risk Appetite Metric</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.matrix} width={"58.2rem"} />
      </div>
    </div>
  );
};
const RiskAppetiteThresholdView = (props) => {
  return (
    <div style={{ marginRight: "6rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Tolerance limit</div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.currentUnit}
          data={props.unitList}
          DropDownButtonStyle={
            riskEditStyle.riskThresholdTypeDropDownButtonStyle
          }
          disabled={true}
        />
        <ThresholdLowerInputView
          unit={props.currentUnit}
          lower={props.riskAppetiteLow}
        />

        {/*<ThresholdMiddleRange*/}
        {/*  unit={props.currentUnit}*/}
        {/*  lower={props.riskAppetiteLow}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
        {/*<ThresholdUpperInputView*/}
        {/*  unit={props.currentUnit}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
      </div>
    </div>
  );
};
const RiskAppetiteNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>NO.</div>
      <div style={riskEditStyle.contentTextStyle}>
        {props.riskAppetiteNumber}
      </div>
    </div>
  );
};

const TextContent = (props) => {
  return (
    <div
      style={
        props.short
          ? { ...riskEditStyle.ShortTextWrapperStyle, width: props.width }
          : { ...riskEditStyle.TextWrapperStyle, width: props.width }
      }
    >
      <div style={riskEditStyle.TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};

const ThresholdLowerInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safe_zone === "low"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        onChange={props.handleRiskAppetiteLowerThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};
// const ThresholdMiddleRange = (props) => {
//   return (
//     <div style={riskEditStyle.thresholdMiddleStyle}>
//       {props.lower}
//       {props.unit === "%" ? "%" : ""}-{props.upper}
//       {props.unit === "%" ? "%" : ""}
//     </div>
//   );
// };
// const ThresholdUpperInput = (props) => {
//   return (
//     <div style={{ position: "relative" }}>
//       <span style={riskEditStyle.ThresholdSymbolStyle}> {">"} </span>
//       <NonValidationInput
//         fieldstyle={
//           props.safe_zone === "high"
//             ? riskEditStyle.ThresholdSafeInputFieldStyle
//             : riskEditStyle.ThresholdDangerInputFieldStyle
//         }
//         value={props.upper}
//         onChange={props.handleRiskAppetiteHigherThresholdChange}
//         index={props.index}
//       />
//       {props.unit === "%" && <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>}
//     </div>
//   );
// };

const ThresholdLowerInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={riskEditStyle.ThresholdSafeInputFieldStyle}
        value={props.lower}
        disabled={true}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};
// const ThresholdUpperInputView = (props) => {
//   return (
//     <div style={{ position: "relative" }}>
//       <span style={riskEditStyle.ThresholdSymbolStyle}> {">"} </span>
//       <NonValidationInput
//         fieldstyle={riskEditStyle.ThresholdDangerInputFieldStyle}
//         value={props.upper}
//         disabled={true}
//       />
//       {props.unit === "%" && <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>}
//     </div>
//   );
// };
export { RiskAppetite };
