import "./set-public-path";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

import { RiskBasicInfo } from "./src/page/riskBasicInfo";
import { RiskSummary } from "./src/page/riskSummary";
import { RiskEditManagement } from "./src/page/riskEditManagement";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
});

export const { bootstrap, mount, unmount } = lifecycles;
export { RiskBasicInfo, RiskSummary, RiskEditManagement };
