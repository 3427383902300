import React from "react";
import {
  mapping,
  NonValidationInput,
  CustomizedCheckBox,
  DropDownListButton,
  ButtonSolid,
  FormatDate,
  CalculateDate,
  riskEditStyle,
} from "@aim-mf/styleguide";
import moment from "moment";
// this part i think can use AutoComplete from styleguide

let KRIPeriodCount = 6;

class KRIValueEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        props.data.value !== undefined && props.data.value.length !== 0
          ? props.data.value
              .sort((a, b) => {
                return a.period > b.period ? 1 : -1;
              })
              .slice(-KRIPeriodCount)
          : defaultValue(props.data.frequency, props.data.id),
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      let value =
        nextProps.data.value !== undefined && nextProps.data.value.length !== 0
          ? this.props.data.value
              .sort((a, b) => {
                return a.period > b.period ? 1 : -1;
              })
              .slice(-KRIPeriodCount)
          : defaultValue(nextProps.data.frequency, nextProps.data.id);
      this.setState({ value: value });
    }
  }

  render() {
    // let value = this.props.data.value.slice(-6);
    let listOption = [
      this.props.data.qualilabel1,
      this.props.data.qualilabel2,
      this.props.data.qualilabel3,
    ];
    let frequency = this.props.data.frequency;
    return (
      <div style={riskEditStyle.KRIValueCreationFormWrapStyle}>
        <div style={riskEditStyle.RiskCreationFormStyle}>
          <div style={riskEditStyle.KRIFormBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Entry values for selected KRI
            </div>
            <div style={{ height: "50px" }} />
            <ContentHeader
              frequency={
                frequency === "Annually"
                  ? "Year"
                  : frequency === "Monthly"
                  ? "Month"
                  : frequency === "Daily"
                  ? "Date"
                  : frequency === "Quarterly"
                  ? "Quarter"
                  : "Year"
              }
            />
            {/*{[0, 1, 2, 3, 4, 5].map((value) => {*/}
            {/*  return (*/}
            {/*    <Content*/}
            {/*      period={today}*/}
            {/*      frequency={frequency}*/}
            {/*      periodOffset={value}*/}
            {/*    />*/}
            {/*  );*/}
            {/*})}*/}
            {this.state.value
              .sort((a, b) => {
                return a.period < b.period ? 1 : -1;
              })
              .map((value, index) => {
                return (
                  <Content
                    listOption={listOption}
                    category={this.props.data.category}
                    period={value.period}
                    value={value.value}
                    frequency={frequency}
                    index={index}
                    onChange={this.onValueChange}
                  />
                );
              })}
            <ButtonSolid
              name={"Add New"}
              width={riskEditStyle.KRIValueButtonWidth}
              height={riskEditStyle.KRIValueButtonHeight}
              clickEvent={() => {
                let value = this.state.value;
                value.push({
                  value: 0,
                  period: CalculateDate(
                    value[0].period,
                    frequency === "Annually",
                    frequency === "Monthly",
                    0
                  ),
                  KRI_id: this.props.data.id,
                });
                this.setState({ value: value });
              }}
            />
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={riskEditStyle.KRIValueButtonWidth}
                height={riskEditStyle.KRIValueButtonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Save"}
                color={"Primary"}
                width={riskEditStyle.KRIValueButtonWidth}
                height={riskEditStyle.KRIValueButtonHeight}
                clickEvent={() => {
                  this.props.submitFunction(
                    this.state.value,
                    this.props.data.id
                  );
                  this.props.cancelFunction();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onValueChange = (index, value) => {
    let kriValue = this.state.value;
    kriValue[index].value = value === "" ? value : parseInt(value);
    kriValue = kriValue.sort((a, b) => {
      return a.period < b.period ? 1 : -1;
    });
    this.setState({ value: kriValue });
  };
}
const ContentHeader = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={{ width: "45%" }}>
        <div style={mapping["forms/label/1-default"]}>Value</div>
      </div>
      <div style={{ width: "45%" }}>
        <div style={mapping["forms/label/1-default"]}>{props.frequency}</div>
      </div>
    </div>
  );
};

const Content = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      {props.category === "Qualitative" && (
        <div style={{ color: "white" }}>
          <DropDownListButton
            index={props.index}
            value={props.listOption[props.value]}
            data={props.listOption}
            CustomOnChange={(event) => {
              props.onChange(
                props.index,
                props.listOption.indexOf(event.value)
              );
            }}
            DropDownButtonStyle={riskEditStyle.KRIDropDownButtonStyle}
            disabled={props.disabled}
          />
        </div>
      )}
      {props.category !== "Qualitative" && (
        <div style={{ width: "45%" }}>
          <NonValidationInput
            fieldstyle={riskEditStyle.KRIValueInputFieldStyle}
            value={props.value}
            width={"100%"}
            onChange={(event) => {
              props.onChange(props.index, event.value);
            }}
          />
        </div>
      )}
      <div style={{ width: "45%" }}>
        <div style={{ ...mapping["forms/label/1-default"], padding: 0 }}>
          {FormatDate(
            props.period,
            props.frequency === "Annually"
              ? "YYYY"
              : props.frequency === "Monthly"
              ? "YYYY-MMM"
              : props.frequency === "Daily"
              ? "YYYY-MM-DD"
              : props.frequency === "Quarterly"
              ? "YYYY-"
              : "YYYY"
          )}
          {props.frequency === "Quarterly"
            ? "Q" + moment(props.period).quarter()
            : ""}
        </div>
      </div>
    </div>
  );
};

const defaultValue = (frequency, KRI_id) => {
  let today = new Date();
  if (frequency === "Quarterly") {
    return [
      {
        value: 0,
        period: moment(today).quarter(moment(today).quarter()),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -3, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -6, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -9, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -12, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -15, 0).toDate(),
        KRI_id: KRI_id,
      },
    ];
  } else {
    return [
      { value: 0, period: today, KRI_id: KRI_id },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -1 : 0,
          frequency === "Monthly" ? -1 : 0,
          frequency === "Daily" ? -1 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -2 : 0,
          frequency === "Monthly" ? -2 : 0,
          frequency === "Daily" ? -2 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -3 : 0,
          frequency === "Monthly" ? -3 : 0,
          frequency === "Daily" ? -3 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -4 : 0,
          frequency === "Monthly" ? -4 : 0,
          frequency === "Daily" ? -4 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -5 : 0,
          frequency === "Monthly" ? -5 : 0,
          frequency === "Daily" ? -5 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
    ];
  }
};

export { KRIValueEntry };
