import React from "react";
import { mapping, IconSVG, riskEditStyle } from "@aim-mf/styleguide";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

class MatrixImpactExplanation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cellRender = (cell, props) => {
    let dataItemArray = props.dataItem[props.field].split("\n");
    if (props.field === "Parameters") {
      if (props.dataItem.Parameters === "Reputation") {
        if (props.dataItem.Insignificant.startsWith("Incidents and/or ")) {
          return (
            <td rowSpan={2}>
              <div style={{ width: "fit-content", margin: "auto" }}>
                {props.dataItem[props.field].toString()}
              </div>
            </td>
          );
        } else {
          return null;
        }
      }
    }
    return (
      <td>
        {dataItemArray.map((data) => {
          return (
            <div style={{ width: "fit-content", margin: "auto" }}>{data}</div>
          );
        })}
      </td>
    );
  };

  render() {
    return (
      <div style={riskEditStyle.RiskCreationFormWrapStyle}>
        <div style={riskEditStyle.RiskCreationFormStyle}>
          <div style={riskEditStyle.formBgStyle}>
            <div style={riskEditStyle.popupContentMargin}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={riskEditStyle.closeButton}
                onClick={this.props.handleInformationClose}
              >
                <IconSVG
                  name={"cancel"}
                  color={mapping["Color_Basic_Light"]}
                  size={2}
                />
              </div>

              <div style={riskEditStyle.FormTitleStyle}> impact parameters</div>
              <Grid
                style={riskEditStyle.tableStyle}
                data={this.props.data}
                cellRender={this.cellRender}
              >
                <Column field="Parameters" title="Parameters" />
                <Column field="Insignificant" title="Insignificant" />
                <Column field="Minor" title="Minor" />
                <Column field="Moderate" title="Moderate" />
                <Column field="Major" title="Major" />
                <Column field="Severe" title="Severe" />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { MatrixImpactExplanation };
