import React from "react";
import { SideBar } from "./sideBar";
import { RiskBasicInfo } from "./riskBasicInfo";
import { RiskSummary } from "./riskSummary";
import { RiskEditManagement } from "./riskEditManagement";
import {
  AddUser,
  TaskForm,
  ConfirmationPopup,
  Loading,
  mapping,
  riskEditStyle,
} from "@aim-mf/styleguide";
import { KRIValueEntry } from "./riskRegisterComponents/KRIValueEntry";
import { MatrixLikelihoodExplanation } from "./riskRegisterComponents/MatrixLikelihoodExplanation";
import { MatrixImpactExplanation } from "./riskRegisterComponents/MatrixImpactExplanation";
import moment from "moment";
import { ABCDemoRiskEdit } from "./ABCDemo/ABCDemoRiskEdit";
import {fakeRiskListData} from "./ABCDemo/fakeData";

class RiskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList: [
        { name: "sustainability", label: "Sustainability" },
        { name: "ABC", label: "ABC" },
        { name: "fraud", label: "Fraud" },
        { name: "dataPrivacy", label: "Data Privacy" },
        { name: "cyberSecurity", label: "Cyber Security" },
        { name: "criticalInfra", label: "Critical Infra" },
        { name: "others", label: "Others" },
      ],
      display: "Risk Summary",
      currentUser: {},
      newRiskDetail: this.decodeRiskUser(this.props.riskDetail),
      AddUser: false,
      createTask: false,
      kriEntry: { display: false },
      title: "",
      Confirmation: { display: false },
      MatrixLikelihoodExplanation: false,
      MatrixImpactExplanation: false,
      reLock: false,
    };
  }

  closeCallBack = (event) => {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "you are about to leave the page";
    this.props.unlockRisk(this.props.riskDetail.id);
    this.setState({ reLock: true });
    sessionStorage.setItem(
      "newRiskDetail",
      JSON.stringify(this.state.newRiskDetail)
    );
  };

  componentWillUnmount() {
    this.props.unlockRisk(this.props.riskDetail.id);
    window.removeEventListener("beforeunload", this.closeCallBack);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.closeCallBack);
  }

  APStatusGenerated = (riskDetail) => {
    let newRiskDetail = riskDetail;
    newRiskDetail.actionplans.forEach((AP) => {
      if (new Date() > new Date(AP.deadline + " 23:59:59")) {
        AP.status = "Overdue";
      } else if (new Date() < new Date(AP.deadline + " 00:00:00")) {
        AP.status = "On Track";
      } else {
        AP.status = "In Progress";
      }
    });
    return newRiskDetail;
  };

  decodeUserDetail = (field, riskDetail) => {
    let newRiskDetail = riskDetail;
    if (field === "owner") {
      newRiskDetail.owner.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.owner[index] = ownerDetail[0];
      });
    } else if (field === "delegate") {
      newRiskDetail.delegate.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.delegate[index] = ownerDetail[0];
      });
    } else if (field === "riskappetite") {
      newRiskDetail[field].forEach((item) => {
        item.provided_by.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.provided_by[index] = ownerdetail[0];
        });
      });
    } else {
      newRiskDetail[field].forEach((item) => {
        item.owner.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.owner[index] = ownerdetail[0];
        });
      });
    }
    return newRiskDetail;
  };
  encodeUserDetail = (field, riskDetail) => {
    if (field === "owner") {
      riskDetail.owner = riskDetail.owner.map((a) => a.username);
    } else if (field === "delegate") {
      riskDetail.delegate = riskDetail.delegate.map((a) => a.username);
    } else if (field === "riskappetite") {
      riskDetail[field].forEach((item, index) => {
        riskDetail[field][index].provided_by = item.provided_by.map(
          (a) => a.username
        );
      });
    } else {
      riskDetail[field].forEach((item, index) => {
        riskDetail[field][index].owner = item.owner.map((a) => a.username);
      });
    }
    return riskDetail;
  };

  removeFieldID = (fieldList, data) => {
    delete data.id;
    delete data.riskappetite.id;
    fieldList.forEach((field) => {
      if (data[field] !== undefined) {
        data[field].forEach((item, index) => {
          delete data[field][index].id;
        });
      }
      // need to remove extra CSA ID in the controls
      if (field === "controls") {
        data[field].forEach((item, index) => {
          delete data[field][index].control_self_assessment_id;
        });
      }
    });

    return data;
  };

  removeKRIValue = (data) => {
    data.KRIs.forEach((kri) => {
      delete kri.value;
    });
    return data;
  };

  packRiskDataForSubmission = () => {
    let data = JSON.parse(JSON.stringify(this.state.newRiskDetail));
    data = this.encodeUserDetail("controls", data);
    data = this.encodeUserDetail("KRIs", data);
    data = this.encodeUserDetail("actionplans", data);
    data = this.encodeUserDetail("riskappetite", data);
    data = this.encodeUserDetail("owner", data);
    data = this.encodeUserDetail("delegate", data);
    let riskID = data.id;
    data = this.removeFieldID(
      ["controls", "KRIs", "actionplans", "riskappetite"],
      data
    );
    data = this.removeKRIValue(data);
    data.number = this.state.newRiskDetail.riskNumber;
    return [data, riskID];
  };

  decodeRiskUser = (riskDetail) => {
    let newRiskDetail = this.decodeUserDetail(
      "controls",
      JSON.parse(JSON.stringify(riskDetail))
    );
    newRiskDetail = this.decodeUserDetail("KRIs", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("actionplans", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("owner", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("delegate", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("riskappetite", newRiskDetail);
    newRiskDetail = this.APStatusGenerated(newRiskDetail);
    return newRiskDetail;
  };

  componentWillReceiveProps(nextProps, nextContext) {
    // update newriskdetail only if props.riskdetail changed, and sessionStorage has no backup
    if (
      JSON.stringify(nextProps.riskDetail) !==
        JSON.stringify(this.props.riskDetail) &&
      nextProps.userList.length !== 0 &&
      sessionStorage.getItem("newRiskDetail") == null
    ) {
      let newRiskDetail = this.decodeRiskUser(nextProps.riskDetail);
      this.setState({ newRiskDetail: newRiskDetail });
    }

    sessionStorage.removeItem("newRiskDetail");

    // populate controls
    if (
      JSON.stringify(nextProps.controlAutoPopulateDetail) !==
        JSON.stringify(this.props.controlAutoPopulateDetail) &&
      Object.keys(nextProps.controlAutoPopulateDetail).length !== 0
    ) {
      let newRiskDetail = JSON.parse(JSON.stringify(this.state.newRiskDetail));

      let populatedControl = JSON.parse(
        JSON.stringify(nextProps.controlAutoPopulateDetail)
      );

      // decode owner for controls only
      populatedControl.owner.forEach((username, index) => {
        let ownerdetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        populatedControl.owner[index] = ownerdetail[0];
      });
      // dun overwrite the control number
      populatedControl.number =
        newRiskDetail.controls[this.props.controlSearchIndex].number;
      newRiskDetail.controls[this.props.controlSearchIndex] = populatedControl;
      this.setState({ newRiskDetail: newRiskDetail });
    }
  }

  render() {
    //console.log(this.props.riskDetail)
    // eslint-disable-next-line no-console
    // console.log("risk edit",this.props.fullRiskList);
    if (Object.keys(this.state.newRiskDetail).length === 0) {
      return <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />;
    }

    if (this.state.reLock) {
      this.setState({ reLock: false });
      this.props.reloadAll(this.props.riskDetail.id);
    }

    let currentdata = JSON.parse(JSON.stringify(this.state.newRiskDetail));
    currentdata = this.encodeUserDetail("controls", currentdata);
    currentdata = this.encodeUserDetail("KRIs", currentdata);
    currentdata = this.encodeUserDetail("actionplans", currentdata);
    currentdata = this.encodeUserDetail("riskappetite", currentdata);
    currentdata = this.encodeUserDetail("owner", currentdata);
    currentdata = this.encodeUserDetail("delegate", currentdata);
    let disableSendDelegate =
      JSON.stringify(currentdata.delegate) ===
      JSON.stringify(this.props.riskDetail.delegate);
    let disableSendOwner =
      JSON.stringify(currentdata.owner) ===
      JSON.stringify(this.props.riskDetail.owner);

    //some field it should not check on diff
    // currentdata.owner = this.props.riskDetail.owner;
    // currentdata.delegate = this.props.riskDetail.delegate;
    currentdata.versions = this.props.riskDetail.versions;
    currentdata.KRIs.forEach((kri, index) => {
      if (this.props.riskDetail.KRIs[index]) {
        kri.value = this.props.riskDetail.KRIs[index].value;
      }
    });

    let disableSubmit =
      JSON.stringify(currentdata) === JSON.stringify(this.props.riskDetail);
    let disableSaveChange =
      JSON.stringify(currentdata) === JSON.stringify(this.props.riskDetail);

    let parentRiskList = this.props.fullRiskList.filter((risk) => {
      return risk.isParentRisk === true;
    });
    let parentRiskDropDownItem = parentRiskList.map(
      ({ name, id, category }) => ({ name, id, category })
    );

    let sideMenuParentRisk = this.props.riskDetail.isParentRisk
      ? this.props.riskDetail
      : this.props.fullRiskList.filter((risk) => {
          return risk.sourceRiskID == this.props.riskDetail.parentRisk_id;
        })[0];
    let sideMenuSubrisk = this.props.riskDetail.isParentRisk
      ? this.props.fullRiskList.filter((risk) => {
          return (
            risk.id !== this.props.riskDetail.id &&
            this.props.riskDetail.sub_risks
              .map((a) => a.name)
              .includes(risk.name)
          );
        })
      : this.props.fullRiskList.filter((risk) => {
          return risk.parentRisk_id == this.props.riskDetail.parentRisk_id;
        });
    let sideMenuData = {
      parentRisk: sideMenuParentRisk,
      subRisk: sideMenuSubrisk,
    };

    //console.log(this.props.userList);
    //console.log(this.props.riskDetail.locked_by);
    let rights = {
      ...this.props.riskDetail.access_right,
      edit:
        (this.props.riskDetail.locked_by === undefined ||
          this.props.riskDetail.locked_by === null ||
          this.props.riskDetail.locked_by ===
            this.props.currentUser.username) &&
        this.props.riskDetail.access_right.edit
          ? 1
          : 0,
      approve:
        (this.props.riskDetail.locked_by === undefined ||
          this.props.riskDetail.locked_by === null ||
          this.props.riskDetail.locked_by ===
            this.props.currentUser.username) &&
        this.props.riskDetail.access_right.approve
          ? 1
          : 0,
    };
    return (
      <div id={"risk-edit-wrapper"} style={riskEditStyle.RiskEditSideStyle}>
        <div
          style={Object.assign(
            {},
            { display: "inline-flex", width: "100%" },
            this.state.AddUser ||
              this.state.createTask ||
              this.state.Confirmation.display ||
              this.props.loading ||
              this.state.kriEntry.display ||
              this.state.MatrixImpactExplanation ||
              this.state.MatrixLikelihoodExplanation
              ? riskEditStyle.BlurEffectStyle
              : {}
          )}
        >
          <SideBar
            riskList={sideMenuData}
            focusedID={this.props.riskDetail.sourceRiskID}
            handleUpdateWithNewRiskDetailToLatest={
              this.props.handleUpdateWithNewRiskDetailToLatest
            }
          />
          {this.props.riskDetail.name === "Anti-Bribery and Corruption Risk" &&
            <ABCDemoRiskEdit riskList={fakeRiskListData} />
          }
          {this.props.riskDetail.name !==
            "Anti-Bribery and Corruption Risk" && (
            <div style={riskEditStyle.RiskEditMainStyle}>
              {this.props.riskDetail.locked_by &&
                this.props.riskDetail.locked_by !==
                  this.props.currentUser.username && (
                  <div style={riskEditStyle.topNotificationStickyBar}>
                    {
                      this.props.userList.filter((user) => {
                        return (
                          user.username === this.props.riskDetail.locked_by
                        );
                      })[0].display_name
                    }
                    is currently editing the risk register, you will not be able
                    to edit this risk register until he/she finish
                  </div>
                )}
              <RiskBasicInfo
                isSubEntity={this.props.isSubEntity}
                parentEntityRisks={this.props.parentEntityRisks}
                riskDetail={this.state.newRiskDetail}
                // parentRiskTier={this.props.parentRiskTier}
                userList={this.props.userList}
                handleTabChange={this.handleTabChange}
                handleRiskTierChange={this.handleRiskTierChange}
                handleSubmitChange={this.handleSubmitChange}
                handleAddUser={this.handleAddUser}
                handleUpdateWithNewRiskDetail={
                  this.props.handleUpdateWithNewRiskDetail
                }
                rights={rights}
                disableSubmit={disableSubmit}
                disableSendDelegate={disableSendDelegate}
                disableSendOwner={disableSendOwner}
                disableSaveChange={disableSaveChange}
                handleApprove={this.handleApprove}
                handleSendToDelegate={this.handleSendToDelegate}
                handleSendToOwner={this.handleSendToOwner}
                handleSaveAsDraft={this.handleSaveAsDraft}
                handleRiskDescriptionChange={this.handleRiskDescriptionChange}
                handleRiskNameChange={this.handleRiskNameChange}
                handleRiskNumberChange={this.handleRiskNumberChange}
                handleRiskMappingChange={this.handleRiskMappingChange}
                handleCategoryChange={this.handleCategoryChange}
                handleParentRiskChange={this.handleParentRiskChange}
                CategoryList={this.props.CategoryList}
                parentRiskDropDownItem={parentRiskDropDownItem}
                handleConfirmation={this.handleConfirmation}
                riskTypeList={this.state.typeList}
                handleRiskTypeChange={this.handleRiskTypeChange}
                handleITRiskTypeChange={this.handleITRiskTypeChange}
                handleNonITRiskTypeChange={this.handleNonITRiskTypeChange}
              />
              {this.state.display === "Risk Summary" && (
                <RiskSummary
                  lastEdit={
                    this.state.newRiskDetail.createdUser === "SYSTEM"
                      ? "SYSTEM"
                      : this.props.userList.filter((a) => {
                          return (
                            a.username === this.state.newRiskDetail.createdUser
                          );
                        })[0].display_name
                  }
                  riskDetail={this.state.newRiskDetail}
                  handleRiskAppetiteChange={this.handleRiskAppetiteChange}
                  handleAddUser={this.handleAddUser}
                  handleRiskRatingChange={this.handleRiskRatingChange}
                  rights={rights}
                  handleCreateTask={this.handleCreateTask}
                  handleRiskAppetiteLevelChange={
                    this.handleRiskAppetiteLevelChange
                  }
                  handleRiskAppetiteStatementChange={
                    this.handleRiskAppetiteStatementChange
                  }
                  handleLikelihoodInformationClick={
                    this.handleLikelihoodInformationClick
                  }
                  handleImpactInformationClick={
                    this.handleImpactInformationClick
                  }
                  unlockRisk={this.props.unlockRisk}
                />
              )}
              {this.state.display === "Risk Management" && (
                <RiskEditManagement
                  lastEdit={
                    this.state.newRiskDetail.createdUser === "SYSTEM"
                      ? "SYSTEM"
                      : this.props.userList.filter((a) => {
                          return (
                            a.username === this.state.newRiskDetail.createdUser
                          );
                        })[0].display_name
                  }
                  handleKRIEntry={this.handleKRIEntry}
                  riskDetail={this.state.newRiskDetail}
                  handleRiskControlChange={this.handleRiskControlChange}
                  handleRiskDriverChange={this.handleRiskDriverChange}
                  handleRiskConseqChange={this.handleRiskConseqChange}
                  handleKRIChange={this.handleKRIChange}
                  handleActionPlanChange={this.handleActionPlanChange}
                  handleAddUser={this.handleAddUser}
                  rights={rights}
                  searching={this.props.searching}
                  handleSearchControls={this.props.handleSearchControls}
                  controlsList={this.props.controlsList}
                  controlSearchIndex={this.props.controlSearchIndex}
                  handleControlDetailRequest={
                    this.props.handleControlDetailRequest
                  }
                  handleCreateTask={this.handleCreateTask}
                />
              )}
            </div>
          )}
        </div>
        {this.state.MatrixLikelihoodExplanation && (
          <MatrixLikelihoodExplanation
            data={LikelihoodParam}
            handleInformationClose={this.handleInformationClose}
          />
        )}
        {this.state.MatrixImpactExplanation && (
          <MatrixImpactExplanation
            data={ImpactParam}
            handleInformationClose={this.handleInformationClose}
          />
        )}
        {this.state.AddUser && (
          <AddUser
            title={this.state.title}
            userList={this.props.userList}
            currentUser={this.state.currentUser}
            handleAddUserClose={this.handleAddUserClose}
            handleAddUserSubmit={this.handleAddUserSubmit}
          />
        )}
        {this.state.createTask && (
          <TaskForm
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ createTask: false });
            }}
            submitFunction={this.taskSubmitFunction}
          />
        )}
        {this.state.Confirmation.display && (
          <ConfirmationPopup
            title={this.state.Confirmation.title}
            content={this.state.Confirmation.content}
            cancelFunction={this.state.Confirmation.cancelFunction}
            confirmFunction={this.state.Confirmation.confirmFunction}
          />
        )}
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
        {this.state.kriEntry.display && (
          <KRIValueEntry
            data={this.state.kriEntry.data}
            cancelFunction={this.handleKRIEntryCancel}
            submitFunction={this.handleKRIEntrySubmit}
          />
        )}
      </div>
    );
  }
  handleInformationClose = () => {
    this.setState({
      MatrixImpactExplanation: false,
      MatrixLikelihoodExplanation: false,
    });
  };

  handleLikelihoodInformationClick = () => {
    this.setState({
      MatrixLikelihoodExplanation: true,
    });
  };
  handleImpactInformationClick = () => {
    this.setState({
      MatrixImpactExplanation: true,
    });
  };
  handleKRIEntry = (data) => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ kriEntry: { display: true, data: data } });
  };
  handleKRIEntryCancel = (data) => {
    this.setState({ kriEntry: { display: false } });
  };
  handleKRIEntrySubmit = (data, kri_id) => {
    data.forEach((value) => {
      if (value.id === undefined) {
        value.period = moment(value.period)
          .format()
          .replace("+08:00", "")
          .replace("T", " ");
        this.props.createKRIValue(value, kri_id);
      } else {
        let id = value.id;
        delete value.id;
        this.props.updateKRIValue(value, id, kri_id);
      }
    });
    //this.setState({kriEntry:{display:false}})
  };
  handleRiskDescriptionChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.description = value;
    this.setState({ newRiskDetail: newRisk });
  };
  handleRiskNameChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.name = value;
    this.setState({ newRiskDetail: newRisk });
  };
  handleRiskNumberChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.riskNumber = value;
    this.setState({ newRiskDetail: newRisk });
  };
  handleRiskMappingChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.mapping_risk_id = value;
    this.setState({ newRiskDetail: newRisk });
  };
  handleAddUser = (field, index, currentUser, title) => {
    this.setState({
      AddUser: true,
      title: title,
      currentUser: { field: field, index: index, currentUser: currentUser },
    });
  };
  handleConfirmation = (title, content, confirmFunction) => {
    const confirmationFunction = () => {
      confirmFunction();
      this.setState({
        Confirmation: { display: false },
      });
    };
    const cancelFunction = () => {
      this.setState({
        Confirmation: { display: false },
      });
    };
    this.setState({
      Confirmation: {
        display: true,
        title: title,
        content: content,
        confirmFunction: confirmationFunction,
        cancelFunction: cancelFunction,
      },
    });
  };
  handleCreateTask = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      createTask: true,
    });
  };
  handleAddUserClose = () => {
    this.setState({ AddUser: false, currentUser: {} });
  };
  handleAddUserSubmit = (owner) => {
    let newRiskDetail = this.state.newRiskDetail;
    if (this.state.currentUser.field === "risk") {
      newRiskDetail.owner = owner;
    } else if (this.state.currentUser.field === "delegate") {
      newRiskDetail.delegate = owner;
    } else if (this.state.currentUser.field === "riskappetite") {
      newRiskDetail.riskappetite[
        this.state.currentUser.index
      ].provided_by = owner;
    } else {
      newRiskDetail[this.state.currentUser.field][
        this.state.currentUser.index
      ].owner = owner;
    }
    this.setState({ AddUser: false, currentUser: {} });
  };

  handleTabChange = (tabLabel) => {
    this.setState({ display: tabLabel });
  };

  handleRiskTierChange = (event) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.tier = parseInt(event.value.split(" ")[1]);
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskRatingChange = (type, axis, value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk[type][axis] = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteChange = (newAppetite) => {
    //console.log(newAppetite)
    let newRisk = this.state.newRiskDetail;
    newRisk.riskappetite = newAppetite;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteLevelChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.risk_appetite_level = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteStatementChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.risk_appetite_statement = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskDriverChange = (newDriver) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.drivers = newDriver;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskConseqChange = (newConseq) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.consequences = newConseq;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskControlChange = (newControl) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.controls = newControl;
    this.setState({ newRiskDetail: newRisk });
  };

  handleKRIChange = (newKRI) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.KRIs = newKRI;
    this.setState({ newRiskDetail: newRisk });
  };

  handleActionPlanChange = (newActionPlan) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.actionplans = newActionPlan;
    newRisk = this.APStatusGenerated(newRisk);
    this.setState({ newRiskDetail: newRisk });
  };

  handleSubmitChange = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleUpdateRiskDetailSubmit({ data: data }, riskID);
  };

  handleApprove = () => {
    let riskID = this.state.newRiskDetail.id;
    //console.log(data)
    this.props.handleApprove(riskID);
  };
  handleSendToDelegate = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSendToDelegate({ data: data }, riskID);
  };
  handleSendToOwner = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSendToOwner({ data: data }, riskID);
  };
  handleSaveAsDraft = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSaveAsDraft({ data: data }, riskID);
  };
  handleCategoryChange = (category) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.category = category.value;
    newRisk.category_id =
      this.props.CategoryList.indexOf(category.value, 0) + 1;
    this.setState({ newRiskDetail: newRisk });
  };
  handleRiskTypeChange = (type) => {
    let newRisk = this.state.newRiskDetail;
    newRisk[type] = !newRisk[type];
    this.setState({ newRiskDetail: newRisk });
  };
  handleITRiskTypeChange = () => {
    let newRisk = this.state.newRiskDetail;
    newRisk.it_risk = !newRisk.it_risk;
    this.setState({ newRiskDetail: newRisk });
  };
  handleNonITRiskTypeChange = () => {
    let newRisk = this.state.newRiskDetail;
    newRisk.non_it_risk = !newRisk.non_it_risk;
    this.setState({ newRiskDetail: newRisk });
  };
  taskSubmitFunction = (task) => {
    //console.log(task)
    task.task_from = this.props.currentUser.username;
    let taskPackage = { data: task };
    this.props.handleCreateTask(taskPackage);
    this.setState({ createTask: false });
  };
  handleParentRiskChange = (event) => {
    let newRisk = this.state.newRiskDetail;
    let parentRiskName = event.value.name;
    let parentID = event.value.id;
    newRisk.parentRisk_id = parentID;
    newRisk.parent_risk_name = parentRiskName;
    this.setState({ newRiskDetail: newRisk });
  };
}

let LikelihoodParam = [
  {
    Parameters: "Probability",
    Rare: "≤ 5% chance of occurring",
    Unlikely: "> 5% to 25% chance of occurring",
    Possible: "> 25% to 75% chance of occurring",
    Likely: "> 75% to 95% chance of occurring",
    "Almost Certain": "> 95% chance of occurring",
  },

  {
    Parameters: "Qualitative Descriptors",
    Rare: "Event may occur only in exceptional circumstances",
    Unlikely: "Event could occur at some time",
    Possible: "Event will occur at some time",
    Likely: "Event probably occur in most circumstances",
    "Almost Certain": "Event is expected to occur in most circumstances\n",
  },

  {
    Parameters: "Time to\ndisruption/occurrence",
    Rare: "More than 5 years",
    Unlikely: "> 3 to 5 years",
    Possible: "> 2 to 3 years",
    Likely: "> 1 to 2 years",
    "Almost Certain": "Less than 12 months",
  },
];
let ImpactParam = [
  {
    Parameters: "Operational Impact",
    Insignificant:
      "Disruption for less than 6 hours with negligible impact  on service levels  to students",
    Minor:
      "Critical functions  brought to a temporary  disruption (6 hrs to <12 hrs) or impact on service levels  to students",
    Moderate:
      "Critical functions  brought to a moderate  disruption (12 hrs to <24 hrs ) or  moderately  affecting service  levels to students",
    Major:
      "Critical functions  brought to a major  disruption (1 to <3 days) or severely  affecting service  levels to students",
    Severe:
      "Critical functions  brought to a  prolonged  disruption (3 days or more) or crippling service levels to  students",
  },
  {
    Parameters: "Management  Effort",
    Insignificant: "Matters can be resolved by junior staff",
    Minor: "Matters can be resolved by managers",
    Moderate: "Matters can be resolved by the Head of Departments",
    Major:
      "Matters escalated up to the Chief Executive Officers and/ or President",
    Severe: "Matters escalated up to the board or council level",
  },
  {
    Parameters: "Workplace Health\nand Safety",
    Insignificant:
      "No injuries to employees or third parties (customers or vendors)",
    Minor:
      "No or minor injuries to employees or third parties (customers or vendors)",
    Moderate:
      "Out-patient medical treatment required for employees or third parties (customers or vendors)",
    Major:
      "Limited in-patient care required for employees or third parties (customers or vendors)",
    Severe:
      "Significant injuries or fatalities to employees or third parties (customers or vendors)",
  },
  {
    Parameters: "Impact On\nGrowth Rate",
    Insignificant: "No change on last year’s total enrolment",
    Minor: "> 0% to 0.5% decrease  on last year’s total enrolment",
    Moderate: "> 0.5% to 1% decrease on last year’s total enrolment",
    Major: "> 1% to 3% decrease on last year’s total enrolment",
    Severe: "> 3% decrease on last year’s total enrolment",
  },
  {
    Parameters: "Reputation",
    Insignificant:
      "Incidents and/or publicity on matters that have no consequence or noticeable impact on SUSS’s  \n" +
      "reputation​",
    Minor:
      "Incidents and/or publicity which can be easily mitigated that have minimal consequence or impact on SUSS’s  \n" +
      "reputation",
    Moderate:
      "Incidents and/or publicity that have escalated to a level that have some impact on SUSS’s reputation and/or brought to attention of relevant Government authorities​\n",
    Major:
      "Incidents and/or publicity that have escalated to a level that have considerable impact on SUSS’s reputation and/or resulted in official inquiry by relevant Government \n" +
      "authorities​\n",
    Severe:
      "Incidents and/or publicity that have escalated to a level that have serious impact on SUSS’s reputation and/or resulted in official censure by relevant Government \n" +
      "authorities​\n",
  },
  {
    Parameters: "Reputation",
    Insignificant:
      "Written or email complains resolved internally  without any negative publicity​\n",
    Minor:
      "Repeated written or email complaints by stakeholders resolved internally without negative publicity​\n",
    Moderate:
      "Repeated written or email complaints by stakeholders that are not resolved, with the potential to be prolonged​\n" +
      "Or Complaints on social media​\n" +
      "(> 1 day for social media; > 3 days for  written letter/email)\n",
    Major:
      "Publicity in local media and social media​\n" +
      "(> 3 days for social media; > 5 days for mainstream media)​​\n",
    Severe:
      "Widespread and prolonged negative publicity in local media and social media​ (> 5 days for social media; > 1 week on mainstream media)​\n" +
      "​\n" +
      "*Any fraud reported is deemed severe\n",
  },
  {
    Parameters: "Financial Loss",
    Insignificant: "≤ 1%\n" + "(≤ ~$0.4m)",
    Minor: "> 1% to 2.5%\n" + "(> ~$0.4m to $1m)",
    Moderate: "> 2.5% to 5%\n" + "(> ~$1m to $2m)",
    Major: "> 5% to 10%\n" + "(> ~$2m to $4m)",
    Severe: "> 10%\n" + "(> ~$4m)",
  },
];
export { RiskEdit };
