import React from "react";
import {
  Pill,
  Risk_Matrix_Component,
  RiskEditCardHeader,
  DropDownListButton,
  getRiskRating,
  riskEditStyle,
} from "@aim-mf/styleguide";

let riskLevel = ["Very Low", "Low", "Moderate", "High", "Very High"];
let likelihoodLevel = [
  "Very Unlikely",
  "Unlikely",
  "Moderate",
  "Likely",
  "Very Likely",
];

//  --- Get pill colour based on risk rating
function pillColor(rating) {
  if (rating === 4) {
    return riskEditStyle.riskRating4Color;
  } else if (rating === 3) {
    return riskEditStyle.riskRating3Color;
  } else if (rating === 2) {
    return riskEditStyle.riskRating2Color;
  } else if (rating === 1) {
    return riskEditStyle.riskRating1Color;
  }
}

const ratingMaping = {
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Very High",
};

class RiskRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      createNewRisk: false,
    };
  }

  render() {
    var matrix = {
      gross: this.props.riskDetail.gross,
      residual: this.props.riskDetail.residual,
      target: this.props.riskDetail.target,
    };
    return (
      <div style={riskEditStyle.RiskRatingStyle}>
        <div style={riskEditStyle.RiskRatingContentStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          {/* ACTUAL CONTENT --------------------------------------------------- */}
          <body>
            <RatingDetail
              matrix={matrix}
              handleRiskRatingChange={this.handleRiskRatingChange}
              rights={this.props.rights}
            />
            {/* RIGHT SIDE CONTENT --------------------- */}
            <div style={riskEditStyle.rightBox}>
              <div style={riskEditStyle.riskRatingMatrixStyle}>
                <Risk_Matrix_Component
                  MatrixSetting={this.state.matrixSetting}
                  singleRisk={true}
                  riskRating={[
                    {
                      Gross: this.props.riskDetail.gross,
                      Residual: this.props.riskDetail.residual,
                      Target: this.props.riskDetail.target,
                    },
                  ]}
                  categoryList={["Gross", "Residual", "Target"]}
                  handleLikelihoodInformationClick={
                    this.props.handleLikelihoodInformationClick
                  }
                  handleImpactInformationClick={
                    this.props.handleImpactInformationClick
                  }
                />
              </div>
            </div>
          </body>
        </div>
      </div>
    );
  }
  handleRiskRatingChange = (event, type, axis) => {
    let rating =
      axis === "likelihood"
        ? likelihoodLevel.indexOf(event.value) + 1
        : riskLevel.indexOf(event.value) + 1;
    this.props.handleRiskRatingChange(type, axis, rating);
    // update rating
    let likelihood =
      axis === "likelihood" ? rating : this.props.riskDetail[type].likelihood;
    let impact =
      axis === "impact" ? rating : this.props.riskDetail[type].impact;
    let riskRating = getRiskRating(likelihood, impact);
    this.props.handleRiskRatingChange(type, "rating", riskRating);
  };
}

const RatingDetail = (props) => {
  return (
    // LEFT SIDE CONTENT ---------------------
    <div style={riskEditStyle.leftBox}>
      <RatingDetailHeader />
      {props.rights.edit ? (
        <div>
          <RatingContent
            type={"gross"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
          <RatingContent
            type={"residual"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
          <RatingContent
            type={"target"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
        </div>
      ) : (
        <div>
          <RatingContentView type={"gross"} matrix={props.matrix} />
          <RatingContentView type={"residual"} matrix={props.matrix} />
          <RatingContentView type={"target"} matrix={props.matrix} />
        </div>
      )}
    </div>
  );
};

const RatingDetailHeader = () => {
  return (
    // --- TOP ROW HEADERS
    <div style={riskEditStyle.leftBoxHeader}>
      <div style={riskEditStyle.leftBoxHeaderTextShort}>
        <span>&nbsp;</span>
      </div>
      <div style={riskEditStyle.leftBoxHeaderText}>LIKELIHOOD</div>
      <div style={riskEditStyle.leftBoxHeaderText}>IMPACT</div>
      {/*the last one need some special style to fit the width*/}
      <div
        style={{
          ...riskEditStyle.leftBoxHeaderText,
          width: "5rem",
          paddingRight: 0,
        }}
      >
        RATING
      </div>
    </div>
  );
};

const RatingContentView = (props) => {
  return (
    <div style={riskEditStyle.leftSubBox}>
      <div style={riskEditStyle.leftBoxHeaderTextShort}>{props.type}</div>

      <div style={riskEditStyle.leftBoxViewWrapperStyle}>
        <Pill
          content={props.matrix[props.type].likelihood}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillViewStyle}
        />
        <div style={riskEditStyle.leftSubBoxText}>
          {likelihoodLevel[props.matrix[props.type].likelihood - 1]}
        </div>
        <Pill
          content={props.matrix[props.type].impact}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillViewStyle}
        />
        <div style={riskEditStyle.leftSubBoxText}>
          {riskLevel[props.matrix[props.type].impact - 1]}
        </div>
        <Pill
          content={ratingMaping[props.matrix[props.type].rating]}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillViewStyle}
        />
      </div>
    </div>
  );
};

const RatingContent = (props) => {
  const handleRiskRatingLikelihoodChange = (event) => {
    props.handleRiskRatingChange(event, props.type, "likelihood");
  };
  const handleRiskRatingImpactChange = (event) => {
    props.handleRiskRatingChange(event, props.type, "impact");
  };
  return (
    <div style={riskEditStyle.leftSubBox}>
      <div style={riskEditStyle.leftBoxHeaderTextShort}>{props.type}</div>

      <div style={riskEditStyle.leftBoxWrapperStyle}>
        <Pill
          content={props.matrix[props.type].likelihood}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillStyle}
        />
        <DropDownListButton
          value={likelihoodLevel[props.matrix[props.type].likelihood - 1]}
          data={likelihoodLevel}
          CustomOnChange={handleRiskRatingLikelihoodChange}
          DropDownButtonStyle={riskEditStyle.riskRatingDropDownButtonStyle}
        />
        <Pill
          content={props.matrix[props.type].impact}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillStyle}
        />
        <DropDownListButton
          value={riskLevel[props.matrix[props.type].impact - 1]}
          data={riskLevel}
          CustomOnChange={handleRiskRatingImpactChange}
          DropDownButtonStyle={riskEditStyle.riskRatingDropDownButtonStyle}
        />
        <Pill
          content={ratingMaping[props.matrix[props.type].rating]}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={riskEditStyle.pillStyle}
        />
      </div>
    </div>
  );
};

export { RiskRating };
