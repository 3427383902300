import React from "react";
import {
  AutoSizeTextArea,
  ButtonSolid,
  CustomDatePicker,
  CustomizedCheckBox,
  FormatDate,
  IconSVG,
  mapping,
  NameCard,
  Pill,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  riskEditStyle,
} from "@aim-mf/styleguide";

class ActionPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={riskEditStyle.followingCardPadding}>
        <div style={riskEditStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.actionPlans.length !== 0 &&
              this.props.actionPlans.map((actionPlan, index) => {
                if (this.props.rights.edit === 1) {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ActionPlanCard
                        index={index}
                        actionPlan={actionPlan}
                        handleAPDescriptionChange={
                          this.handleAPDescriptionChange
                        }
                        handleAPOwnerChange={this.handleAPOwnerChange}
                        handleAPStatusChange={this.handleAPStatusChange}
                        handleAPTimelineChange={this.handleAPTimelineChange}
                        handleDelete={this.handleDelete}
                        handleAPCompleteChange={this.handleAPCompleteChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ActionPlanCardView actionPlan={actionPlan} />
                    </div>
                  );
                }
              })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Action Plan"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingAPs = this.props.actionPlans;
    let existingAPsNumber = existingAPs.map((AP) => {
      return parseInt(AP.number.slice(1));
    });
    if (existingAPsNumber.length === 0) {
      existingAPsNumber.push(0);
    }
    let newAPNumber = "A" + (Math.max(...existingAPsNumber) + 1);
    let newAP = {
      id: 0,
      number: newAPNumber,
      description: "\u00A0",
      owner: [],
      status: "Automatic",
      deadline: FormatDate(new Date(), "YYYY-MM-DD"),
      is_completed: false,
    };
    existingAPs.push(newAP);
    this.props.handleActionPlanChange(existingAPs);
  };
  handleAPDescriptionChange = (value, index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs[index].description = value;
    this.props.handleActionPlanChange(existingAPs);
  };

  handleAPStatusChange = (event) => {
    let existingAPs = this.props.actionPlans;
    let index = event.target.props.index;
    existingAPs[index].frequency = event.value;
    this.props.handleActionPlanChange(existingAPs);
  };
  handleAPTimelineChange = (date, index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs[index].deadline = FormatDate(date, "YYYY-MM-DD");
    this.props.handleActionPlanChange(existingAPs);
  };
  handleAPCompleteChange = (index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs[index].is_completed = !existingAPs[index].is_completed;
    this.props.handleActionPlanChange(existingAPs);
  };
  handleDelete = (index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs.splice(index, 1);
    this.props.handleActionPlanChange(existingAPs);
  };

  handleAPOwnerChange = (index) => {
    this.props.handleAddUser(
      "actionplans",
      index,
      this.props.actionPlans[index].owner,
      "Assign Action Plan Owner"
    );
  };
}

const ActionPlanCard = (props) => {
  return (
    <div style={riskEditStyle.cardStyle}>
      {/*top right tool bar*/}
      <div style={riskEditStyle.completeCheckBoxWrapper}>
        <CustomizedCheckBox
          value={props.actionPlan.is_completed === true}
          onCheckChange={() => {
            props.handleAPCompleteChange(props.index);
          }}
        />
        <div style={riskEditStyle.CheckBoxLabelStyle}>Mark as done</div>
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
          size={1}
        />
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ActionPlanNumber actionPlanNumber={props.actionPlan.number} />
          <ActionPlanDescription
            description={props.actionPlan.description}
            handleAPDescriptionChange={props.handleAPDescriptionChange}
            index={props.index}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          <ActionPlanOwner
            APOwners={props.actionPlan.owner}
            handleAPOwnerChange={props.handleAPOwnerChange}
            index={props.index}
          />
          <ActionPlanStatus
            handleAPStatusChange={props.handleAPStatusChange}
            disabled={true}
            status={props.actionPlan.status}
            APTimeline={props.actionPlan.deadline}
          />
          <ActionPlanTimeline
            index={props.index}
            APTimeline={props.actionPlan.deadline}
            handleAPTimelineChange={props.handleAPTimelineChange}
          />
        </div>
      </div>
    </div>
  );
};
const ActionPlanNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>NO.</div>
      <div style={riskEditStyle.contentTextStyle}>{props.actionPlanNumber}</div>
    </div>
  );
};
const ActionPlanDescription = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextInput
          content={props.description}
          onCustomChange={props.handleAPDescriptionChange}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ActionPlanOwner = (props) => {
  const OwnerChange = () => {
    props.handleAPOwnerChange(props.index);
  };
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Action Plan Owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.APOwners.length !== 0 &&
          props.APOwners.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={riskEditStyle.buttonHeight}
        width={riskEditStyle.buttonWidth}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const ActionPlanStatus = (props) => {
  let dueDays = Math.ceil(
    (new Date(props.APTimeline) - new Date()) / (1000 * 60 * 60 * 24)
  );
  return (
    <div style={{ paddingRight: "2rem", width: "11rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Status</div>
      <div style={{ paddingTop: "1.7rem", display: "inline-flex" }}>
        <Pill
          pillStyle={{ height: "1.5rem" }}
          contentStyle={{
            display: "flex",
            height: "1.5rem",
            alignItems: "center",
          }}
          content={props.status}
          color={statusColorMapping[props.status]}
        />
        {dueDays > 0 && (
          <div style={riskEditStyle.statusDueDate}>Due in {dueDays} days</div>
        )}
        {dueDays === 0 && (
          <div style={riskEditStyle.statusDueDate}>Due Today</div>
        )}
        {dueDays < 0 && (
          <div style={riskEditStyle.statusDueDate}>Overdue {-dueDays} days</div>
        )}
      </div>
    </div>
  );
};
const ActionPlanTimeline = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Timeline</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <CustomDatePicker
          index={props.index}
          CustomOnChange={props.handleAPTimelineChange}
          value={props.APTimeline}
        />
      </div>
    </div>
  );
};

const ActionPlanCardView = (props) => {
  return (
    <div style={riskEditStyle.cardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ActionPlanNumber actionPlanNumber={props.actionPlan.number} />
          <ActionPlanDescriptionView
            description={props.actionPlan.description}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          <ActionPlanOwnerView APOwners={props.actionPlan.owner} />
          <ActionPlanStatus
            handleAPStatusChange={props.handleAPStatusChange}
            disabled={true}
            status={props.actionPlan.status}
            APTimeline={props.actionPlan.deadline}
          />
          <ActionPlanTimelineView APTimeline={props.actionPlan.deadline} />
        </div>
      </div>
    </div>
  );
};
const ActionPlanDescriptionView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const ActionPlanOwnerView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={riskEditStyle.sectionTitleStyle}>Action Plan Owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.APOwners.length !== 0 &&
          props.APOwners.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const ActionPlanTimelineView = (props) => {
  return (
    <div>
      <div style={riskEditStyle.sectionTitleStyle}>Timeline</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <CustomDatePicker value={props.APTimeline} disabled={true} />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={riskEditStyle.APInputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={riskEditStyle.APTextWrapperStyle}>
      <div style={riskEditStyle.TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const statusColorMapping = {
  "On Track": mapping["Color_Platform_RiskLow"] + "66",
  Overdue: mapping["Color_Platform_RiskHigh"] + "66",
  "In Progress": mapping["Color_Platform_RiskMed"] + "66",
};
export { ActionPlans };
