import React from "react";
import {
  IconButtonSolid,
  IconSVG,
  mapping,
  NonValidationInput,
  RiskEditCardHeader,
  AutoSizeTextArea,
  riskEditStyle,
} from "@aim-mf/styleguide";

class DriversAndConsequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={riskEditStyle.topCardPadding}>
        <div style={riskEditStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          <div style={{ margin: "1.25rem" }}>
            {this.props.rights.edit === 1 ? (
              <ContentBox
                title={"Risk Drivers"}
                content={this.props.drivers}
                onAddNew={this.handleAddNewDriver}
                onNewItemChange={this.handleDriverNewItemChange}
                handleDelete={this.handleDeleteDriver}
              />
            ) : (
              <ContentBoxView
                title={"Risk Drivers"}
                content={this.props.drivers}
              />
            )}
          </div>
          <div style={{ margin: "1.43rem", marginTop: 0 }}>
            {this.props.rights.edit === 1 ? (
              <ContentBox
                title={"Risk Consequences"}
                content={this.props.consequences}
                onAddNew={this.handleAddNewConseq}
                onNewItemChange={this.handleConseqNewItemChange}
                handleDelete={this.handleDeleteConseq}
              />
            ) : (
              <ContentBoxView
                title={"Risk Consequences"}
                content={this.props.consequences}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  handleAddNewDriver = () => {
    let array = this.props.drivers;
    array.push("");
    this.props.handleRiskDriverChange(array);
  };
  handleAddNewConseq = () => {
    let array = this.props.consequences;
    array.push("");
    this.props.handleRiskConseqChange(array);
  };
  handleDriverNewItemChange = (value, index) => {
    let array = this.props.drivers;
    array[index] = value;
    this.props.handleRiskDriverChange(array);
  };
  handleConseqNewItemChange = (value, index) => {
    let array = this.props.consequences;
    array[index] = value;
    this.props.handleRiskConseqChange(array);
  };
  handleDeleteDriver = (index) => {
    let array = this.props.drivers;
    array.splice(index, 1);
    this.props.handleRiskDriverChange(array);
  };
  handleDeleteConseq = (index) => {
    let array = this.props.consequences;
    array.splice(index, 1);
    this.props.handleRiskConseqChange(array);
  };
}

const ContentBox = (props) => {
  return (
    <div style={riskEditStyle.fullBoxStyle}>
      <div style={{ paddingTop: "1rem", paddingLeft: "1rem" }}>
        <div style={riskEditStyle.sectionTitleStyle}>{props.title}</div>
      </div>
      <div style={{ paddingLeft: "1rem" }}>
        {props.content.map((content, index) => {
          return (
            <div
              key={index}
              style={{ padding: "0.4rem", display: "inline-flex" }}
            >
              <TextInput
                id={index}
                value={content}
                onCustomChange={props.onNewItemChange}
              />
              <div style={{ width: "0.625rem" }} />
              <IconSVG
                name={"delete"}
                color={mapping["Color_Basic_Mute_2"]}
                onClick={() => {
                  props.handleDelete(index);
                }}
                size={1}
              />
            </div>
          );
        })}
        <div style={{ padding: "0.4rem", paddingBottom: "2rem" }}>
          <IconButtonSolid
            height={"2rem"}
            width={"5rem"}
            icon={<IconSVG name={"newRisk"} size={"0.8"} color={"#ffffff"} />}
            name={"Add"}
            iconPos={"Right"}
            clickEvent={props.onAddNew}
          />
        </div>
      </div>
    </div>
  );
};

const ContentBoxView = (props) => {
  return (
    <div style={{ ...riskEditStyle.fullBoxStyle, height: "fit-content" }}>
      <div style={{ padding: "1rem" }}>
        <div style={riskEditStyle.sectionTitleStyle}>{props.title}</div>
      </div>
      <div style={{ paddingLeft: "1rem" }}>
        {props.content.map((content, index) => {
          return (
            <div key={index} style={{ padding: "0.4rem" }}>
              <TextContent content={content} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.id);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={riskEditStyle.InputFieldWithIconStyle}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={riskEditStyle.TextWrapperWithIconStyle}>
      <div style={riskEditStyle.TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};

export { DriversAndConsequences };
