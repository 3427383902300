import React from "react";
import { IconSVG, riskEditStyle } from "@aim-mf/styleguide";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverID: 1,
      riskList: this.props.riskList,
    };
  }

  render() {
    if (!this.state.riskList.parentRisk) {
      return <div />;
    }
    return (
      <div name={"SideBar"} style={riskEditStyle.SideBarStyle}>
        <div name={"sidebar-title"} style={riskEditStyle.titleCategoryFont}>
          {this.state.riskList.parentRisk.category.toUpperCase()}
        </div>
        <ul name={"sidebar-menu"}>
          <li
            style={
              this.state.riskList.parentRisk.id === this.state.hoverID ||
              this.state.riskList.parentRisk.id === this.props.focusedID
                ? riskEditStyle.MenuWrapperHover
                : riskEditStyle.MenuWrapper
            }
            onMouseEnter={() => {
              this.setState({ hoverID: this.state.riskList.parentRisk.id });
            }}
            onMouseLeave={() => {
              this.setState({ hoverID: -1 });
            }}
          >
            {this.props.focusedID ===
              this.state.riskList.parentRisk.sourceRiskID && (
              <div style={riskEditStyle.indicatorBar} />
            )}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              style={riskEditStyle.parentMenuFont}
              onClick={() => {
                this.props.handleUpdateWithNewRiskDetailToLatest(
                  this.state.riskList.parentRisk.id
                );
              }}
            >
              <IconSVG name={"work"} color={"white"} size={"0.6"} />
              {this.state.riskList.parentRisk.name}
            </div>
          </li>
          {this.state.riskList.subRisk.sort((a,b)=>{return a.name>b.name?1:-1}).map((risk) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={risk.id}
                style={
                  risk.id === this.state.hoverID ||
                  risk.id === this.props.focusedID
                    ? riskEditStyle.MenuWrapperHover
                    : riskEditStyle.MenuWrapper
                }
                onMouseEnter={() => {
                  this.setState({ hoverID: risk.id });
                }}
                onMouseLeave={() => {
                  this.setState({ hoverID: -1 });
                }}
                onClick={() => {
                  this.props.handleUpdateWithNewRiskDetailToLatest(risk.id);
                }}
              >
                {this.props.focusedID == risk.sourceRiskID && (
                  <div style={riskEditStyle.indicatorBar} />
                )}
                <div style={riskEditStyle.subMenuFont}>
                  &#11172;&nbsp;&nbsp;&nbsp;{risk.name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export { SideBar };
