import React from "react";
import { mapping, IconSVG, riskEditStyle } from "@aim-mf/styleguide";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

class MatrixLikelihoodExplanation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cellRender = (cell, props) => {
    let dataItemArray = props.dataItem[props.field].split("\n");
    return (
      <td>
        {dataItemArray.map((data) => {
          return (
            <div style={{ width: "fit-content", margin: "auto" }}>{data}</div>
          );
        })}
      </td>
    );
  };

  render() {
    return (
      <div style={riskEditStyle.RiskCreationFormWrapStyle}>
        <div style={riskEditStyle.RiskCreationFormStyle}>
          <div style={riskEditStyle.formBgStyle}>
            <div style={riskEditStyle.popupContentMargin}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={riskEditStyle.closeButton}
                onClick={this.props.handleInformationClose}
              >
                <IconSVG
                  name={"cancel"}
                  color={mapping["Color_Basic_Light"]}
                  size={2}
                />
              </div>

              <div style={riskEditStyle.FormTitleStyle}>
                likelihood parameters
              </div>
              <Grid
                style={riskEditStyle.tableStyle}
                data={this.props.data}
                cellRender={this.cellRender}
              >
                <Column field="Parameters" title="Parameters" />
                <Column field="Rare" title="Rare" />
                <Column field="Unlikely" title="Unlikely" />
                <Column field="Possible" title="Possible" />
                <Column field="Likely" title="Likely" />
                <Column field="Almost Certain" title="Almost Certain" />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "100rem",
};
const formBgStyle = {
  height: "fit-content",
  width: "fit-content",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  display: "inline-flex",
  position: "relative",
};
const FormTitleStyle = {
  ...mapping["heading/h5/lightleft"],
  marginBottom: "15px",
};
export { MatrixLikelihoodExplanation };
